import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { ApiAuthorization } from "../../resources/ApiAuthorization";
import { clearSession, startSession } from "../../store/session/sessionActions";
import { StoreState } from "../../store/storeReducer";
import ApiTokenProvider, { SessionProviderDispatchProps, SessionProviderStateProps, } from "./ApiTokenProvider";
import {push} from "connected-react-router";

const mapStateToProps: MapStateToProps<SessionProviderStateProps, {}, StoreState> = (state) => ({
    authorization: state.session || undefined,
});

const mapDispatchToProps: MapDispatchToProps<SessionProviderDispatchProps, {}> = (dispatch) => ({
    setAuthorization(auth?: ApiAuthorization) {
        if (auth) {
            dispatch(startSession(auth));
        } else {
            dispatch(clearSession());
        }
    },
    redirectToLogin() {
        dispatch(push("/login"));
    }
});

const ApiTokenProviderContainer = connect(mapStateToProps, mapDispatchToProps)(ApiTokenProvider);

export default ApiTokenProviderContainer;
