import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { StoreState } from "../../../store/storeReducer";
import LikertScaleElements, {
  LikertScaleElementsDispatchProps as DispatchProps,
  LikertScaleElementsStateProps as StateProps,
} from "./LikertScaleElements";
import {getQuestionnaireFromSurveyForm} from "../store/questionnaires/surveyFormQuestionnairesSelectors";
import {
  sortQuestionnaireScaleInSurveyForm,
  updateQuestionnaireScaleInSurveyForm
} from "../store/questionnaires/surveyFormQuestionnairesActions";

interface OwnProps {
  questionnaireId: string;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, StoreState> = (state, props) => {
  const questionnaire = getQuestionnaireFromSurveyForm(props.questionnaireId)(state);

  return {
    items: questionnaire.scale ? questionnaire.scale.elements : [],
    questionnaireId: props.questionnaireId,
  };
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch, props) => ({
  update(elements) {
    dispatch(updateQuestionnaireScaleInSurveyForm({
      questionnaireId: props.questionnaireId,
      elements: elements,
    }));
  },
  sort(dragIndex, hoverIndex) {
    dispatch(sortQuestionnaireScaleInSurveyForm(dragIndex, hoverIndex, props.questionnaireId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LikertScaleElements);
