import { Questionnaire } from "../../../../resources/questionnaire/Questionnaire";
import { SurveyFormQuestionnairesType } from "./surveyFormQuestionnairesTypes";
import {LikertScale} from "../../../../resources/questionnaire/QuestionnaireElement";

export const addQuestionnaireToSurveyForm = (
    questionnaire: Questionnaire,
    surveyId: string,
) => ({
    type: SurveyFormQuestionnairesType.ADD,
    payload: {
        questionnaire,
        surveyId,
    },
});

export const removeQuestionnaireFromSurveyForm = (
    questionnaireId: string,
) => ({
    type: SurveyFormQuestionnairesType.REMOVE,
    payload: questionnaireId,
});

export const updateQuestionnaireInSurveyForm = (
    questionnaire: { questionnaireId: string } & Partial<Questionnaire>,
) => ({
    type: SurveyFormQuestionnairesType.UPDATE,
    payload: questionnaire,
});
export const updateQuestionnaireScaleInSurveyForm = (
    questionnaire: { questionnaireId: string } & LikertScale,
) => ({
    type: SurveyFormQuestionnairesType.UPDATE_SCALE,
    payload: questionnaire,
});

export const sortQuestionnaireScaleInSurveyForm = (
  dragIndex: number,
  hoverIndex: number,
  questionnaireId: string,
) => ({
    type: SurveyFormQuestionnairesType.SORT_SCALE,
    payload: {dragIndex, hoverIndex, questionnaireId},
});

export const sortQuestionnairesInSurveyForm = (surveyId: string, dragIndex: number, hoverIndex: number) => ({
    type: SurveyFormQuestionnairesType.SORT,
    payload: {
      surveyId,
      drag: {dragIndex, hoverIndex},
    },
});

export const setSurveyFormQuestionnaires = (
    questionnaires: Questionnaire[]
) => ({
    type: SurveyFormQuestionnairesType.SET,
    payload: questionnaires,
});
