import * as React from "react";
import { ComponentType } from "react";
import { TextAnswerValueType } from "../../resources/questionnaire/AnswerType";

interface Props {
    type: TextAnswerValueType;
}

const AnswerValueTypeName: ComponentType<Props> = (props) => {
    switch (props.type) {
        case TextAnswerValueType.DATE:
            return <>Data</>;
        case TextAnswerValueType.DEFAULT:
            return <>Krótki tekst</>;
        case TextAnswerValueType.ICD10:
            return <>Kod ICD-10</>;
        case TextAnswerValueType.PESEL:
            return <>PESEL</>;
        case TextAnswerValueType.PHONE:
            return <>Nr telefonu</>;
        case TextAnswerValueType.TEXTAREA:
            return <>Długi tekst</>;
        default:
            console.error(`Unknown answer value type '${props.type}'`);
            return null;
    }
};

export default AnswerValueTypeName;
