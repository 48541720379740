import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, createStore, DeepPartial } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import storeLogger from "./middleware/storeLogger";
import reducer, { StoreState } from "./storeReducer";
import storeSaga from "./storeSaga";
import createSentryMiddleware from "redux-sentry-middleware";
import * as Sentry from "@sentry/browser";
import {BrowserOptions} from "@sentry/browser";
import { TransportOptions } from "@sentry/types";
import {sessionToken} from "./session/sessionSaga";
import {isProduction} from "../isProduction";

if(isProduction){
    const dsn = `${window.location.protocol}//public@${window.location.host}/sentry`;
    const token = localStorage.getItem(sessionToken);
    Sentry.init({
        dsn: dsn,
        transportOptions: token ? {
            dsn: dsn,
            headers: {"Authorization": `Bearer ${token}`},
        } as TransportOptions : undefined,
        release: process.env.APP_VERSION,
        environment: process.env.NODE_ENV,
    } as BrowserOptions);
}

export const browserHistory = createBrowserHistory();

export default (initialState: DeepPartial<StoreState> = {}) => {
    const sagaMiddleware = createSagaMiddleware({
        onError(error: Error, errorInfo): void {
            Sentry.captureException(error);
        }
    });
    const middlewares = [
        storeLogger,
        routerMiddleware(browserHistory),
        sagaMiddleware,
    ];
    if (isProduction) {
        // @ts-ignore
        middlewares.push(createSentryMiddleware(Sentry, {
            getUserContext(state: StoreState) {
                return !!state.currentUser ? {id: state.currentUser.userId} : {};
            },
        }));
    }

    let enhancer = applyMiddleware(
      ...middlewares
    );

    enhancer = composeWithDevTools(enhancer);

    const store = createStore(reducer(browserHistory), initialState, enhancer);

    sagaMiddleware.run(storeSaga);

    return store;
};
