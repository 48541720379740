import { Steps } from "antd";
import * as React from "react";
import { ComponentType } from "react";

const {Step} = Steps;

interface Props {
    current: number;
    steps: number;
    onChange(step: number): void;
}

const QuestionnaireElementModalSteps: ComponentType<Props> = (props) => (
    <Steps current={props.current}>
        {[...Array(props.steps)].fill(null).map((n, i) => (
            <Step key={i} onClick={() => props.onChange(i)}/>
        ))}
    </Steps>
);

export default QuestionnaireElementModalSteps;
