import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { StoreState } from "../../../store/storeReducer";
import {
    addQuestionnaireToSurveyForm,
    sortQuestionnairesInSurveyForm,
} from "../store/questionnaires/surveyFormQuestionnairesActions";
import SurveyFormQuestionnaires, {
    SurveyFormQuestionnairesDispatchProps,
    SurveyFormQuestionnairesStateProps,
} from "./SurveyFormQuestionnaires";

interface OwnProps {
    surveyId: string;
}

const mapStateToProps: MapStateToProps<SurveyFormQuestionnairesStateProps, OwnProps, StoreState> = ({surveyForm}) => ({
    questionnaires: surveyForm.questionnaires,
});

const mapDispatchToProps: MapDispatchToProps<SurveyFormQuestionnairesDispatchProps, OwnProps> = (dispatch, props) => ({
    createQuestionnaire(questionnaire) {
        dispatch(addQuestionnaireToSurveyForm(questionnaire, props.surveyId));
    },
    sortQuestionnaires(dragIndex, hoverIndex) {
        dispatch(sortQuestionnairesInSurveyForm(props.surveyId, dragIndex, hoverIndex));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyFormQuestionnaires);
