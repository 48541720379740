import { QuestionnaireElement } from "../../../../resources/questionnaire/QuestionnaireElement";
import { SurveyFormElementsType } from "./surveyFormElementsTypes";

export const addQuestionnaireElementToSurveyForm = (element: QuestionnaireElement) => ({
    type: SurveyFormElementsType.ADD,
    payload: element,
});

export const removeQuestionnaireElementFromSurveyForm = (elementId: string) => ({
    type: SurveyFormElementsType.REMOVE,
    payload: elementId,
});

export const updateQuestionnaireElementInSurveyForm = (
    element: {elementId: string} & Partial<QuestionnaireElement>,
) => ({
    type: SurveyFormElementsType.UPDATE,
    payload: element,
});

export const sortQuestionnaireElementsInSurveyForm = (
    dragIndex: number,
    hoverIndex: number,
    questionnaireId: string,
) => ({
    type: SurveyFormElementsType.SORT,
    payload: {dragIndex, hoverIndex, questionnaireId},
});

export const setSurveyFormElements = (elements: QuestionnaireElement[]) => ({
    type: SurveyFormElementsType.SET,
    payload: elements,
});
