import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import * as React from "react";
import { ComponentType, forwardRef } from "react";
import { SurveyTemplate } from "../../resources/survey/SurveyTemplate";

const {Option} = Select;
type Props = SelectProps<SurveyTemplate>;

const SurveyTemplateSelect: ComponentType<Props> = (props, ref?: any) => (
    <Select ref={ref} {...props}>
        <Option value={SurveyTemplate.DEFAULT}>Domyślny</Option>
        <Option value={SurveyTemplate.CHILD}>Dziecięcy</Option>
    </Select>
);

export default forwardRef(SurveyTemplateSelect);
