import { Select } from "antd";
import { OptionProps, SelectProps } from "antd/lib/select";
import * as React from "react";
import { ComponentType } from "react";
import QuestionnaireType from "../../resources/questionnaire/QuestionnaireType";

const QuestionnaireTypeSelect: ComponentType<SelectProps<QuestionnaireType>> = (props, ref?: any) => {
    const options: OptionProps[] = [
        {children: "Domyślny", value: QuestionnaireType.DEFAULT},
        {children: "Skala Likerta", value: QuestionnaireType.LIKERT_SCALE},
        {children: "Stopka demograficzna", value: QuestionnaireType.DEMOGRAPHIC, disabled: true},
    ];

    return (
        <Select ref={ref}{...props}>
            {options.map((p) => <Option key={p.value} {...p}/>)}
        </Select>
    );
};

const {Option} = Select;

export default React.forwardRef(QuestionnaireTypeSelect);
