import axios from "axios";
import {call, put, select, takeEvery} from "redux-saga/effects";
import {updateCurrentUser} from "../../../store/currentUser/currentUserActions";
import {
    userDetailsFormError,
    userDetailsFormLoad, userDetailsFormLoaded,
    userDetailsFormSaved,
    userDetailsFormSubmit
} from "./userDetailsFormActions";
import { UserDetailsFormTypes } from "./userDetailsFormTypes";
import generateUuid from "../../../utils/generateUuid";
import {getCurrentUserId} from "../../../store/currentUser/currentUserSelectors";
import {ErrorTypes} from "../../error/store/errorTypes";

export default function*() {
    yield takeEvery(UserDetailsFormTypes.SUBMIT, userDetailsFormSubmitSaga);
    yield takeEvery(UserDetailsFormTypes.LOAD, userDetailsFormLoadSaga);
}

function* userDetailsFormLoadSaga({payload}: ReturnType<typeof userDetailsFormLoad>) {
    try {
        const response = yield call(axios.get, `users/${payload}`);
        yield put(userDetailsFormLoaded(response.data));
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        setTimeout(
          yield put(userDetailsFormError()),
          1000,
        );
        yield call(console.error, e);
    }
}

function* userDetailsFormSubmitSaga({payload}: ReturnType<typeof userDetailsFormSubmit>) {
    try {
        if (!payload.userId) {
            payload.userId = generateUuid();
            yield call(axios.post, 'users', payload);
            yield put(userDetailsFormSaved());

            return;
        }
        const currentUserId = yield select(getCurrentUserId);
        if (currentUserId === payload.userId) {
            yield call(axios.put, `users/${payload.userId}`, payload);
            yield put(userDetailsFormSaved());
            yield put(updateCurrentUser(payload));

            return;
        }
        // else
        yield call(axios.put, `users/${payload.userId}`, payload);
        yield put(userDetailsFormSaved());
    } catch (error) {
        yield put({type: ErrorTypes.GENERAL, error});
        setTimeout(
            yield put(userDetailsFormError()),
            1000,
        );
        yield call(console.error, error);
    }
}
