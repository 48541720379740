import * as React from "react";
import {Button} from "antd";
import {ComponentType} from "react";

export interface EditSurveyButtonDispatchProps {
  onClick(): void;
}

export interface EditSurveyButtonStateProps {
  surveyId: string,
  loading: boolean,
}

type Props = EditSurveyButtonDispatchProps & EditSurveyButtonStateProps;

const EditSurveyButton: ComponentType<Props> = (props) => {
  return (
    <Button
      htmlType="button"
      icon="edit"
      onClick={props.onClick}
      disabled={props.loading}
      loading={props.loading}
    >
      Edycja
    </Button>
  )
};

export default EditSurveyButton;
