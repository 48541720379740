import { StoreState } from "../../../../store/storeReducer";

export const canPublishSurveyForm = () => ({surveyForm: {summary}}: StoreState): boolean => {
    return (
        !summary.loading &&
        summary.data.validDescription &&
        summary.data.validCode &&
        summary.data.validName
    );
};
