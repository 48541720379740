import { connect, MapStateToProps } from "react-redux";
import { StoreState } from "../../store/storeReducer";
import SurveyList, { SurveyListDispatchProps, SurveyListStateProps } from "./SurveyList";
import { SurveyListColumnName } from "./SurveyListColumnName";

interface OwnProps extends  SurveyListDispatchProps {
    page: number;
    pageSize: number;
    columns: SurveyListColumnName[],
}

const mapStateToProps: MapStateToProps<SurveyListStateProps, OwnProps, StoreState> = ({surveyList}, props) => ({
    ...props,
    items: surveyList.items,
    total: surveyList.total,
    loading: surveyList.loading,
});

export default connect(mapStateToProps)(SurveyList);
