import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { StoreState } from "../../../store/storeReducer";
import {
    addQuestionnaireElementToSurveyForm,
    updateQuestionnaireElementInSurveyForm,
} from "../store/elements/surveyFormElementsActions";
import { getQuestionnaireElements } from "../store/elements/surveyFormElementsSelectors";
import DefaultQuestionnaireForm, {
    DefaultQuestionnaireFormDispatchProps as DispatchProps,
    DefaultQuestionnaireFormStateProps as StateProps,
} from "./DefaultQuestionnaireForm";

interface OwnProps {
    questionnaireId: string;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, StoreState> = (state, ownProps) => ({
       elements: getQuestionnaireElements(ownProps.questionnaireId)(state),
       questionnaireId: ownProps.questionnaireId,
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch) => ({
    addElement(element) {
        dispatch(addQuestionnaireElementToSurveyForm(element));
    },
    updateElement(element) {
        dispatch(updateQuestionnaireElementInSurveyForm(element));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultQuestionnaireForm);
