import { push } from "connected-react-router";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { StoreState } from "../../../store/storeReducer";
import { publishSurveyForm } from "../store/publish/surveyFormPublishActions";
import { canPublishSurveyForm } from "../store/summary/surveyFormSummarySelectors";
import SurveyFormFlowControls, {
    SurveyFormFlowControlsDispatchProps,
    SurveyFormFlowControlsStateProps,
} from "./SurveyFormFlowControls";

interface OwnProps {
    surveyId: string;
    step: number;
}

const mapStateToProps: MapStateToProps<SurveyFormFlowControlsStateProps, OwnProps, StoreState> = (state, props) => ({
    step: props.step,
    canPublish: canPublishSurveyForm()(state),
});

const mapDispatchToProps: MapDispatchToProps<SurveyFormFlowControlsDispatchProps, OwnProps> = (dispatch, props) => ({
    change(step) {
        dispatch(push(`/surveys/${props.surveyId}/edit/${step}`));
    },
    publish(): void {
        dispatch(publishSurveyForm(props.surveyId));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyFormFlowControls);
