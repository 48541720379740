import { Reducer } from "redux";
import { QuestionnaireElement } from "../../../../resources/questionnaire/QuestionnaireElement";
import { SurveyFormType } from "../surveyFormTypes";
import {
    addQuestionnaireElementToSurveyForm,
    removeQuestionnaireElementFromSurveyForm,
    setSurveyFormElements,
    sortQuestionnaireElementsInSurveyForm,
    updateQuestionnaireElementInSurveyForm,
} from "./surveyFormElementsActions";
import { SurveyFormElementsType } from "./surveyFormElementsTypes";

export type SurveyFormElementsState = QuestionnaireElement[];

export const surveyFormElementsReducer: Reducer<SurveyFormElementsState> = (state = [], action) => {
    if (action.type as SurveyFormType === SurveyFormType.RESET) {
        return [];
    }

    switch (action.type as SurveyFormElementsType) {
        case SurveyFormElementsType.ADD: {
            const {payload} = action as ReturnType<typeof addQuestionnaireElementToSurveyForm>;

            return state.concat([payload]);
        }
        case SurveyFormElementsType.REMOVE: {
            const {payload} = action as ReturnType<typeof removeQuestionnaireElementFromSurveyForm>;
            return state.filter(({elementId}) => elementId !== payload);
        }
        case SurveyFormElementsType.UPDATE: {
            const {payload} = action as ReturnType<typeof updateQuestionnaireElementInSurveyForm>;

            return state.map((item) => item.elementId === payload.elementId ? ({
                ...item,
                ...payload,
            } as QuestionnaireElement) : item);

        }
        case SurveyFormElementsType.SORT: {
            const {payload} = action as ReturnType<typeof sortQuestionnaireElementsInSurveyForm>;
            const questionnaireId = payload.questionnaireId;

            const notChangedState = state.filter((value: QuestionnaireElement) => value.questionnaireId !== questionnaireId);

            const changingState = state.filter((value: QuestionnaireElement) => value.questionnaireId === questionnaireId);
            const changedState = [...changingState];

            changedState.splice(payload.dragIndex, 1);
            changedState.splice(payload.hoverIndex, 0, changingState[payload.dragIndex]);

            return [...notChangedState, ...changedState];
        }
        case SurveyFormElementsType.SET: {
            const {payload} = action as ReturnType<typeof setSurveyFormElements>;

            return payload;
        }
    }

    return state;
};
