import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { Provider } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import createStore, { browserHistory } from "../../store/createStore";
import ApiTokenProviderContainer from "../apiTokenProvider/ApiTokenProviderContainer";
import DashboardPage from "../DashboardPage";
import EditSurveyPage from "../EditSurveyPage";
import LoginPage from "../LoginPage";
import SurveyListPage from "../surveyList/SurveyListPage";
import UserListPage from "../userList/UserListPage";
import EditUserPage from "../EditUserPage";
import EditProfilePageContainer from "../editUserPage/EditProfilePageContainer";

const App = () => {
    return (
        <Provider store={createStore()}>
            <ApiTokenProviderContainer>
                <ConnectedRouter history={browserHistory}>
                    <Switch>
                        <Route exact path="/" component={DashboardPage}/>
                        <Route exact path="/login" component={LoginPage}/>
                        <Route exact path="/users" component={UserListPage}/>
                        <Route
                          exact
                          path="/users/create"
                          component={EditUserPage}
                        />
                        <Route
                          exact
                          path="/users/edit/:userId"
                          render={(props) => <EditUserPage userId={props.match.params.userId} />}
                        />
                        <Route exact path="/profile/edit" component={EditProfilePageContainer}/>
                        <Route exact path="/surveys" render={() => <Redirect to="/surveys/published/1" />} />
                        <Route exact path="/surveys/:tag/:page" component={SurveyListPage}/>
                        <Route exact path="/surveys/:surveyId/edit/:step" component={EditSurveyPage}/>
                    </Switch>
                </ConnectedRouter>
            </ApiTokenProviderContainer>
        </Provider>
    );
};

export default DragDropContext(HTML5Backend)(App)
