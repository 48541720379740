import {Tabs} from "antd";
import {push} from "connected-react-router";
import * as React from "react";
import {useCallback, useEffect} from "react";
import Helmet from "react-helmet";
import {useDispatch} from "react-redux";
import {RouteComponentProps} from "react-router";
import {AnonymousTag, SurveyStatus} from "../../resources/SurveyEntity";
import {defaultPageSize} from "../../utils/pagination";
import CreateSurveyButtonContainer from "../createSurveyButton/CreateSurveyButtonContainer";
import PanelLayout from "../panelLayout/PanelLayout";
import {loadSurveyList, resetSurveyList} from "./store/surveyListActions";
import {SurveyListColumnName} from "./SurveyListColumnName";
import SurveyListContainer from "./SurveyListContainer";
import styles from "./surveyListPage.module.scss";

const SurveyListPage = (props: RouteComponentProps<Params>) => {
    const {tag} = props.match.params;
    const page = parseInt(props.match.params.page) || 1;

    const dispatch = useDispatch();
    const onChangePage = useCallback((page: number) => {
        dispatch(push(`/surveys/${tag}/${page}`));
    }, [dispatch, tag]);

    const changePane = useCallback((status: string) => {
        dispatch(push(`/surveys/${status}/1`));
    }, [dispatch]);

    useEffect(() => {
        dispatch(loadSurveyList({page, status: tag as SurveyStatus, anonymous: [
            AnonymousTag.NOT_APPLICABLE,
            AnonymousTag.WITH_PERSONAL_DATA,
            AnonymousTag.ANONYMIZED_VERSION,
        ] as AnonymousTag[]}));

        return () => {
            dispatch(resetSurveyList());
        };
    }, [dispatch, page, tag]);

    const surveyList = (
        <SurveyListContainer
            columns={columnsFromTag(tag)}
            page={page}
            pageSize={defaultPageSize}
            onChangePage={onChangePage}
        />
    );

    return (
        <PanelLayout>
            <Helmet>
                <title>Lista badań</title>
            </Helmet>
            <div className={styles.createBtn}>
                <CreateSurveyButtonContainer/>
            </div>
            <Tabs defaultActiveKey={SurveyStatus.PUBLISHED} onChange={changePane}>
                <Tabs.TabPane tab="Opublikowane" key={SurveyStatus.PUBLISHED}>
                    {surveyList}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Szkice" key={SurveyStatus.DRAFT}>
                    {surveyList}
                </Tabs.TabPane>
            </Tabs>
        </PanelLayout>
    );
};

const columnsFromTag = (tag: string) => tag === SurveyStatus.PUBLISHED ? [
    SurveyListColumnName.CODE,
    SurveyListColumnName.NAME,
    SurveyListColumnName.CA,
    SurveyListColumnName.AUTHOR,
    SurveyListColumnName.OPTIONS,
] : [
    SurveyListColumnName.CODE,
    SurveyListColumnName.NAME,
    SurveyListColumnName.AUTHOR,
    SurveyListColumnName.OPTIONS,
];

interface Params {
    page: string;
    tag: string;
}

export default SurveyListPage;
