import { push } from "connected-react-router";
import * as React from "react";
import { ComponentType, useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import PanelLayout from "./panelLayout/PanelLayout";
import { loadCurrentSurveyForm, resetSurveyForm } from "./surveyForm/store/surveyFormActions";
import SurveyFormStep from "./surveyForm/surveyFormStep/SurveyFormStep";
import SurveyFormSteps from "./surveyForm/surveyFormSteps/SurveyFormSteps";

const EditSurveyPage: ComponentType<RouteComponentProps<Params>> = (props) => {
    const {surveyId} = props.match.params;
    const step = parseInt(props.match.params.step) || 0;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadCurrentSurveyForm(surveyId, step));

        return () => {
            dispatch(resetSurveyForm());
        };
    }, [dispatch, surveyId, step]);

    const changeStep = useCallback((step: number) => {
        dispatch(push(`/surveys/${surveyId}/edit/${step}`));
    }, [dispatch, surveyId]);

    return (
        <PanelLayout>
            <Helmet>
                <title>Edycja badania</title>
            </Helmet>
            <SurveyFormSteps
                step={step}
                onChange={changeStep}
            />
            <SurveyFormStep step={step} surveyId={surveyId}/>
        </PanelLayout>
    );
};

interface Params {
    surveyId: string;
    step: string;
}

export default EditSurveyPage;
