import axios from "axios";
import * as React from "react";
import { ComponentType } from "react";
import { ApiAuthorization } from "../../resources/ApiAuthorization";
import configuration from "../../resources/configuration";
import {sessionToken} from "../../store/session/sessionSaga";
import {Modal} from "antd";

export interface SessionProviderStateProps {
    authorization?: ApiAuthorization;
}

export interface SessionProviderDispatchProps {
    setAuthorization(auth: ApiAuthorization | undefined): void;
    redirectToLogin():void;
}

type Props = SessionProviderStateProps & SessionProviderDispatchProps;

let axiosResponseInterceptorId: null|number = null;
let axiosRequestInterceptorId: null|number = null;

const ApiTokenProvider: ComponentType<Props> = (
  {
      children,
      authorization,
      setAuthorization,
      redirectToLogin
  }
) => {
    if (!!axiosResponseInterceptorId || axiosResponseInterceptorId === 0) {
        axios.interceptors.response.eject(axiosResponseInterceptorId);
    }
    if (!!axiosRequestInterceptorId || axiosRequestInterceptorId === 0) {
        axios.interceptors.request.eject(axiosRequestInterceptorId);
    }
    axios.defaults.baseURL = configuration.apiEndpoint;
    axiosResponseInterceptorId = axios.interceptors.response.use((response) => {
        const token = response.headers["x-token"];
        if (token && (!authorization || (authorization.type !== "Bearer" && authorization.value !== token))) {
            setAuthorization({type: "Bearer", value: token});
        }
        return response;
    }, (error) => {
        if (error.response.status === 401) {
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length > 0) {
                setAuthorization(undefined);
                delete axios.defaults.headers.common["Authorization"];

                Modal.confirm({
                    title: "Wiadomość systemowa",
                    content: error.response.data.errors[0].message,
                    okText: "Do strony logowania",
                    onOk: redirectToLogin,
                    cancelButtonProps: {hidden: true},
                });
            } else {
                alert(error);
            }
        }
        throw error;
    });
    axiosRequestInterceptorId = axios.interceptors.request.use((request) => {
        if (!authorization) {
            const token = localStorage.getItem(sessionToken);
            if (token) {
                request.headers["Authorization"] = `Bearer ${token}`;
            }
        }
        return request;
    });

    if (authorization) {
        axios.defaults.headers.common["Authorization"] = `${authorization.type} ${authorization.value}`;
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }

    return <>{children}</>;
};

export default ApiTokenProvider;
