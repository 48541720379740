import { Reducer } from "redux";
import { SurveyFormType } from "../surveyFormTypes";
import { SetSurveyFormSummaryAction } from "./surveyFormSummaryActions";
import { SurveyFormSummaryTypes } from "./surveyFormSummaryTypes";

export interface SurveyFormSummaryData {
    validName: boolean;
    validCode: boolean;
    validDescription: boolean;
}

export interface SurveyFormSummaryState {
    loading: boolean;
    data: SurveyFormSummaryData;
}

const initialState: SurveyFormSummaryState = {
    loading: true,
    data: {
        validCode: true,
        validDescription: true,
        validName: true,
    },
};

const surveyFormSummaryReducer: Reducer<SurveyFormSummaryState> = (state = initialState, action) => {
    switch (action.type as SurveyFormSummaryTypes | SurveyFormType) {
        case SurveyFormSummaryTypes.LOAD:
        case SurveyFormType.RESET:
            return initialState;
        case SurveyFormSummaryTypes.SET:
            const {payload} = action as SetSurveyFormSummaryAction;

            return {
                loading: false,
                data: payload,
            };
    }
    return state;
};

export default surveyFormSummaryReducer;
