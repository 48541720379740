import { Reducer } from "redux";
import { ApiAuthorization } from "../../resources/ApiAuthorization";
import { startSession } from "./sessionActions";
import { SessionType } from "./sessionTypes";

export type SessionState = ApiAuthorization | null;

export const sessionReducer: Reducer<SessionState> = (state = null, action) => {
    switch (action.type as SessionType) {
        case SessionType.START:
            return (action as ReturnType<typeof startSession>).payload;
        case SessionType.CLEAR:
            return null;
    }
    return state;
};
