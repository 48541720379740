export default interface Pagination {
    skip: number;
    take: number;
}

export const parsePagination = (args: any) => {
    const skip = normalizeNumber(0)(parseIntValue(args.skip) || 0);
    const take = normalizeNumber(1, 50)(parseIntValue(args.take) || defaultPageSize);

    return {skip, take};
};

const parseIntValue = (value: any): undefined | number => {
    switch (typeof value) {
        case "number":
            return isNaN(value) ? undefined : value;
        case "string":
            return parseIntValue(parseInt(value, 10));
        default:
            return undefined;
    }
};

const normalizeNumber = (min: number = Number.MIN_VALUE, max: number = Number.MAX_VALUE) => {
    return (value: number) => Math.min(max, Math.max(min, value));
};

export const defaultPageSize = 20;
