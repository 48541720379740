import axios from "axios";
import { AnyAction } from "redux";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { Questionnaire } from "../../../../resources/questionnaire/Questionnaire";
import { loadCurrentSurveyForm } from "../surveyFormActions";
import { SurveyFormType } from "../surveyFormTypes";
import {
    addQuestionnaireToSurveyForm,
    removeQuestionnaireFromSurveyForm,
    setSurveyFormQuestionnaires,
    sortQuestionnaireScaleInSurveyForm,
    sortQuestionnairesInSurveyForm,
    updateQuestionnaireInSurveyForm,
    updateQuestionnaireScaleInSurveyForm
} from "./surveyFormQuestionnairesActions";
import { SurveyFormQuestionnairesType } from "./surveyFormQuestionnairesTypes";
import {getQuestionnaireFromSurveyForm} from "./surveyFormQuestionnairesSelectors";
import {ErrorTypes} from "../../../error/store/errorTypes";

function* surveyFormQuestionnairesSaga() {
    yield takeEvery(SurveyFormQuestionnairesType.ADD, addQuestionnaireSaga);
    yield takeEvery(SurveyFormQuestionnairesType.UPDATE, sendQuestionnaireSaga);
    yield takeEvery(SurveyFormQuestionnairesType.UPDATE_SCALE, sendQuestionnaireScaleSaga);
    yield takeEvery(SurveyFormQuestionnairesType.SORT_SCALE, sortQuestionnaireScaleSaga);
    yield takeEvery(
        (event: AnyAction) => event.type === SurveyFormType.LOAD && event.payload.step === 1,
        loadCurrentSurveySaga,
    );
    yield takeEvery(SurveyFormQuestionnairesType.SORT, sortQuestionnairesSaga);
    yield takeEvery(SurveyFormQuestionnairesType.REMOVE, removeQuestionnaireSaga);
}

function* sortQuestionnairesSaga({payload: {surveyId}}: ReturnType<typeof sortQuestionnairesInSurveyForm>) {
    try {
        const state = yield select();

        const questionnaires = state.surveyForm.questionnaires;

        yield call(axios.patch, `/surveys/${surveyId}/questionnaires/sort`, {
            draggedIds: questionnaires.map((value: Questionnaire) => value.questionnaireId),
        });
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot save questionnaire.", e);
    }
}

function* removeQuestionnaireSaga({payload}: ReturnType<typeof removeQuestionnaireFromSurveyForm>) {
    try {
        yield call(axios.delete, `/questionnaires/${payload}`);
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot remove questionnaire.", e);
    }
}

function* addQuestionnaireSaga({payload: {surveyId, questionnaire}}: ReturnType<typeof addQuestionnaireToSurveyForm>) {
    try {
        yield call(axios.post, `/surveys/${surveyId}/questionnaires`, questionnaire);
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot save questionnaire.", e);
    }
}

function* loadCurrentSurveySaga({payload: {surveyId}}: ReturnType<typeof loadCurrentSurveyForm>) {
    try {
        const response = yield call(axios.get, `/surveys/${surveyId}/questionnaires`);
        yield put(setSurveyFormQuestionnaires(response.data.questionnaires));
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot load survey questionnaires.", e);
    }
}

function* sendQuestionnaireSaga({payload}: ReturnType<typeof updateQuestionnaireInSurveyForm>) {
    try {
        yield call(axios.put, `/questionnaires/${payload.questionnaireId}`, payload);
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot update questionnaire.", e);
    }
}

function* sendQuestionnaireScaleSaga({payload}: ReturnType<typeof updateQuestionnaireScaleInSurveyForm>) {
    try {
        yield call(axios.put, `/questionnaires/${payload.questionnaireId}/scale`, payload);
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot update questionnaire scale.", e);
    }
}

function* sortQuestionnaireScaleSaga({payload: {dragIndex, hoverIndex, questionnaireId}}: ReturnType<typeof sortQuestionnaireScaleInSurveyForm>) {
    try {
        const state = yield select();

        const questionnaire = yield getQuestionnaireFromSurveyForm(questionnaireId)(state);

        yield call(axios.put, `/questionnaires/${questionnaireId}/scale`, questionnaire.scale);
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot update questionnaire scale.", e);
    }
}

export default surveyFormQuestionnairesSaga;
