import { SurveyFormSummaryData } from "./surveyFormSummaryReducer";
import { SurveyFormSummaryTypes } from "./surveyFormSummaryTypes";

export const loadSurveyFormSummary = (surveyId: string) => ({
    type: SurveyFormSummaryTypes.LOAD,
    payload: surveyId,
});

export const setSurveyFormSummary = (data: SurveyFormSummaryData) => ({
    type: SurveyFormSummaryTypes.SET,
    payload: data,
});

export type SetSurveyFormSummaryAction = ReturnType<typeof setSurveyFormSummary>;
