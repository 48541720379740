import { UserEntity } from "../../../resources/UserEntity";
import { defaultPageSize } from "../../../utils/pagination";
import { UserListTypes } from "./userListTypes";

export const loadUserList = (page: number, pageSize: number = defaultPageSize) => ({
    type: UserListTypes.LOAD,
    payload: {page, pageSize},
});

export const readyUserList = (items: UserEntity[], total: number) => ({
    type: UserListTypes.READY,
    payload: {items, total},
});

export const resetUserList = () => ({
    type: UserListTypes.RESET,
});
