import { connect, MapStateToProps } from "react-redux";
import { StoreState } from "../../store/storeReducer";
import UserList, { UserListDispatchProps, UserListStateProps } from "./UserList";

interface OwnProps extends UserListDispatchProps {
    page: number;
    pageSize: number;
}

const mapStateToProps: MapStateToProps<UserListStateProps, OwnProps, StoreState> = ({userList}, props) => ({
    ...props,
    loading: userList.loading,
    items: userList.items,
    total: userList.total,
});

export default connect(mapStateToProps)(UserList);
