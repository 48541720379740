import * as React from "react";
import { ComponentType } from "react";
import QuestionnaireType from "../resources/questionnaire/QuestionnaireType";

interface QuestionnaireProps {
    value: QuestionnaireType;
}

const QuestionnaireTypeName: ComponentType<QuestionnaireProps> = (props) => {
    switch (props.value) {
        case QuestionnaireType.DEFAULT:
            return <>Domyślny</>;
        case QuestionnaireType.LIKERT_SCALE:
            return <>Skala Likerta</>;
        case QuestionnaireType.DEMOGRAPHIC:
            return <>Stopka demograficzna</>;
        default:
            console.error(`Unknown questionnaire type '${props.value}'`);

            return null;
    }
};

export default QuestionnaireTypeName;
