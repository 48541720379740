import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import * as React from "react";
import { ComponentType, forwardRef } from "react";
import { TextAnswerValueType } from "../../resources/questionnaire/AnswerType";

const {Option} = Select;
type Props = SelectProps<TextAnswerValueType>;

const TextAnswerValueTypeSelect: ComponentType<Props> = (props, ref?: any) => (
    <Select ref={ref} {...props}>
        <Option value={TextAnswerValueType.DEFAULT}>Krótki tekst</Option>
        <Option value={TextAnswerValueType.TEXTAREA}>Długi tekst</Option>
        <Option value={TextAnswerValueType.PESEL}>PESEL</Option>
        <Option value={TextAnswerValueType.ICD10}>Kod ICD-10</Option>
        <Option value={TextAnswerValueType.DATE}>Data</Option>
        <Option value={TextAnswerValueType.PHONE}>Nr telefonu</Option>
    </Select>
);

export default forwardRef(TextAnswerValueTypeSelect);
