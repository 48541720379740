import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import { ComponentType, default as React, forwardRef } from "react";
import { QuestionnaireElementType } from "../../resources/questionnaire/QuestionnaireElement";

const {Option} = Select;
type Props = SelectProps<QuestionnaireElementType>;

const QuestionnaireElementTypeSelect: ComponentType<Props> = (props, ref?: any) => (
    <Select ref={ref} {...props}>
        <Option value={QuestionnaireElementType.QUESTION}>
            Pytanie
        </Option>
        <Option value={QuestionnaireElementType.TEXT}>
            Tekst
        </Option>
    </Select>
);

export default forwardRef(QuestionnaireElementTypeSelect);
