import { push } from "connected-react-router";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { getLocation } from "../router/routerSelectors";
import { startSession } from "./sessionActions";
import { SessionType } from "./sessionTypes";

export const sessionToken = "session-token";

export default function*() {
    const token = yield call(() => localStorage.getItem(sessionToken));
    const {pathname} = yield select(getLocation);
    const isLoginPage =pathname === "/login";

    if (null != token) {
        yield put(startSession({type: "Bearer", value: token}));
        if (isLoginPage) {
            yield put(push("/"));
        }
    } else if (!isLoginPage) {
        yield put(push("/login"));
    }

    yield takeEvery(SessionType.START, startSessionSaga);
    yield takeEvery(SessionType.CLEAR, clearSessionSaga);
}

function* startSessionSaga({payload}: ReturnType<typeof startSession>) {
    yield call(() => localStorage.setItem(sessionToken, payload.value));
}

function* clearSessionSaga() {
    yield call(() => localStorage.removeItem(sessionToken));
    yield put(push("/login"));
}
