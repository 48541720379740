import axios from "axios";
import { decode } from "jws";
import { call, put, select, takeLeading, takeEvery } from "redux-saga/effects";
import { clearSession, startSession } from "../session/sessionActions";
import { SessionType } from "../session/sessionTypes";
import { loadCurrentUser, setCurrentUser } from "./currentUserActions";
import { getCurrentUserId } from "./currentUserSelectors";
import { CurrentUserTypes } from "./currentUserTypes";
import {ErrorTypes} from "../../components/error/store/errorTypes";

export default function*() {
    yield takeLeading(CurrentUserTypes.LOAD, loadCurrentUserSaga);
    yield takeEvery(SessionType.START, getUserFromTokenSaga);
    yield takeEvery(SessionType.CLEAR, clearUserSaga);
}

function* loadCurrentUserSaga({payload}: ReturnType<typeof loadCurrentUser>) {
    const userId = yield select(getCurrentUserId);
    if (userId === payload) {
        return;
    }
    try {
        const response = yield call(axios.get,`users/${payload}`);
        yield put(setCurrentUser(response.data));
    } catch (e) {
        yield put(clearSession());
        yield put({type: ErrorTypes.GENERAL, e});
        console.warn("Cannot load current user %o", e);
    }
}

function* getUserFromTokenSaga({payload}: ReturnType<typeof startSession>) {
    if (payload.type === "Bearer") {
        try {
            const token = decode(payload.value);
            yield put(loadCurrentUser(token.payload.sub));

            return;
        } catch (e) {
            yield put({type: ErrorTypes.GENERAL, e});
            console.warn("Cannot decode session token", e);
        }
    }
    yield put(clearSession());
}

function* clearUserSaga() {
    yield put(setCurrentUser(null));
}
