import {Button, Icon, Popconfirm, Table} from "antd";
import { ColumnProps } from "antd/lib/table";
import { ComponentType, default as React } from "react";
import { UserEntity } from "../../resources/UserEntity";
import UserName from "../UserName";
import styles from "../surveyList/surveyListPage.module.scss";

export interface UserListStateProps {
    loading: boolean;
    items: UserEntity[];
    page: number;
    pageSize: number;
    total: number;
}

export interface UserListDispatchProps {
    onChangePage(page: number, pageSize?: number): void;
    onClick(): void;
}

type Props = UserListStateProps & UserListDispatchProps;

const UserList: ComponentType<Props> = ({loading, items, page, pageSize, total, onClick, onChangePage}) => (
  <>
      <div className={styles.createBtn}>
          <Popconfirm
            title="Czy na pewno chcesz utworzyć nowego użytkownika?"
            icon={<Icon type="question-circle" />}
            placement="left"
            okText="Tak"
            cancelText="Nie"
            onConfirm={onClick}
          >
              <Button
                htmlType="button"
                type="primary"
                icon="plus"
              >
                  Utwórz nowego użytkownika
              </Button>
          </Popconfirm>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={items}
        rowKey="userId"
        pagination={{onChange: onChangePage, current: page, pageSize, total}}
      />
  </>
);

const columns: Array<ColumnProps<UserEntity>> = [
    {
        dataIndex: "userId",
        title: "Imię i nazwisko",
        render: (userId, {name, surname}) => (
            <UserName
                userId={userId}
                name={name}
                surname={surname}
            />
        ),
    },
    {dataIndex: "email", title: "E-mail"},
    {
        dataIndex: "actions",
        title: "Opcje",
        render: (t, user) => (
          <Button
            icon="edit"
            href={`/users/edit/${user.userId}`}
          />
        ),
    },
];

export default UserList;
