import {Empty, Table} from "antd";
import { TableProps } from "antd/lib/table";
import { default as React } from "react";
import DragableBodyRow from "./DragableBodyRow";
import "./dragSortingTable.css";

type Props<I> = TableProps<I> & {
    onDragSorting(dragIndex: number, hoverIndex: number): void;
};

function DragSortingTable<T>({onDragSorting, ...restProps}: Props<T>) {
    const components = {
        body: {
            row: DragableBodyRow,
        },
    };

    return (
        <Table
            components={components}
            onRow={(record, index) => ({index, moveRow: onDragSorting})}
            pagination={false}
            locale={{
                emptyText: (<Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="Brak danych"
                />),
            }}
            {...restProps}
        />
    );
}

export default DragSortingTable;
