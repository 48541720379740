import { Reducer } from "redux";
import { SurveyTemplate } from "../../../../resources/survey/SurveyTemplate";
import { SurveyFormDetailsModel } from "../../surveyFormDetails/SurveyFormDetails";
import { LoadCurrentSurveyFormAction } from "../surveyFormActions";
import { SurveyFormType } from "../surveyFormTypes";
import { SetSurveyFormDetails, UpdateSurveyFormDetailsAction } from "./surveyFormDetailsActions";
import { SurveyFormDetailsType } from "./surveyFormDetailsTypes";

export interface SurveyFormDetailsState {
    loading: boolean;
    data: SurveyFormDetailsModel;
}

const initialState: SurveyFormDetailsState = {
    loading: false,
    data: {
        code: "",
        description: "",
        informationAfterSurvey: "",
        informationBeforeSurvey: "",
        name: "",
        template: SurveyTemplate.DEFAULT,
    }
};

export const surveyFormDetailsReducer: Reducer<SurveyFormDetailsState> = (state = initialState, action) => {
    if (action.type as SurveyFormType === SurveyFormType.RESET) {
        return initialState;
    }

    switch (action.type as SurveyFormDetailsType | SurveyFormType) {
        case SurveyFormDetailsType.SET: {
            const {payload} = action as SetSurveyFormDetails;

            return {
                loading: false,
                data: payload
            };
        }
        case SurveyFormType.RESET: {
            return initialState;
        }
        case SurveyFormType.LOAD: {
            const {payload: {step}} = action as LoadCurrentSurveyFormAction;

            return 0 === step ? {
                ...initialState,
                loading: true,
            } : state;
        }
        case SurveyFormDetailsType.UPDATE: {
            const {payload: {details}} = action as UpdateSurveyFormDetailsAction;

            const newState: SurveyFormDetailsState = {
                ...state,
                data: {
                    ...state.data,
                    ...details,
                }
            };

            return newState;
        }
    }

    return state;
};
