import { Form, Input, Modal } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { GetFieldDecoratorOptions } from "antd/lib/form/Form";
import { ComponentType, default as React } from "react";
import QuestionnaireType from "../../../resources/questionnaire/QuestionnaireType";
import { requiredRule } from "../../../utils/validationRules";
import QuestionnaireTypeSelect from "../../questionnaireTypeSelect/QuestionnaireTypeSelect";

export interface QuestionnaireDetailsModalStateProps {
    isEdit: boolean;
    defaultValue: Questionnaire;
    visible: boolean;
    loading?: boolean;
}

export interface QuestionnaireDetailsModalDispatchProps {
    onCancel(): void;

    onSubmit(value: Questionnaire): void;
}

type OwnProps = QuestionnaireDetailsModalStateProps & QuestionnaireDetailsModalDispatchProps;

type Props = FormComponentProps & OwnProps;

const QuestionnaireDetailsModal: ComponentType<Props> = (props) => {
    const {getFieldDecorator} = props.form;

    const submit = () => props.form.validateFields((err, values) => {
        if (!err) {
            props.onSubmit(
                {
                    ...props.defaultValue,
                    ...values,
                },
            );
        }
    });

    const cancel = () => {
        props.form.resetFields();
        props.onCancel();
    };

    return (
        <Modal
            align={null}
            visible={props.visible}
            onOk={submit}
            onCancel={cancel}
            title={props.isEdit ? "Podstawowe dane kwestionariusza - edycja" : "Podstawowe dane kwestionariusza"}
        >
            <Form>
                <Form.Item label="Nazwa">
                    {getFieldDecorator("name", fieldOptions)(
                        <Input placeholder="Nazwa" readOnly={props.loading}/>,
                    )}
                </Form.Item>
                <Form.Item label="Kod">
                    {getFieldDecorator("code", fieldOptions)(
                        <Input placeholder="Kod" readOnly={props.loading}/>,
                    )}
                </Form.Item>
                <Form.Item label="Typ">
                    {getFieldDecorator("type", fieldOptions)(
                        <QuestionnaireTypeSelect placeholder="Typ" disabled={props.loading || props.isEdit}/>,
                    )}
                </Form.Item>
            </Form>
        </Modal>
    );
};

interface Questionnaire {
    name: string;
    code: string;
    type: QuestionnaireType;
}

const fieldOptions: GetFieldDecoratorOptions = {rules: [requiredRule]};

export default Form.create<Props>({
    mapPropsToFields: (props) => ({
       name: Form.createFormField({value: props.defaultValue.name}),
       code: Form.createFormField({value: props.defaultValue.code}),
       type: Form.createFormField({value: props.defaultValue.type}),
    }),
})(QuestionnaireDetailsModal);
