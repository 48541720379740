import { Form, Input, Spin } from "antd";
import { ColProps } from "antd/lib/col";
import { FormComponentProps, FormCreateOption } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import * as React from "react";
import { ComponentType } from "react";
import { SurveyTemplate } from "../../../resources/survey/SurveyTemplate";
import { requiredRule } from "../../../utils/validationRules";
import SurveyTemplateSelect from "../../surveyTemplateSelect/SurveyTemplateSelect";

export interface SurveyFormDetailsStateProps {
    loading: boolean;
    data: SurveyFormDetailsModel;
}

export interface SurveyFormDetailsDispatchProps {
    onChange(model: SurveyFormDetailsModel): void;
}

type Props = FormComponentProps & SurveyFormDetailsDispatchProps & SurveyFormDetailsStateProps;

const SurveyFormDetails: ComponentType<Props> = (props) => {
    const {getFieldDecorator} = props.form;

    return (
        <Spin spinning={props.loading}>
            <Form
                wrapperCol={wrapperCol}
                labelCol={labelCol}
            >
                <Form.Item label="Nazwa">
                    {getFieldDecorator("name", {rules: [requiredRule]})(
                        <Input placeholder="Nazwa"/>,
                    )}
                </Form.Item>
                <Form.Item label="Kod">
                    {getFieldDecorator("code", {rules: [requiredRule]})(
                        <Input placeholder="Kod"/>,
                    )}
                </Form.Item>
                <Form.Item label="Szablon wizualny badania">
                    {getFieldDecorator("template", {
                        rules: [requiredRule],
                        initialValue: SurveyTemplate.DEFAULT,
                    })(
                        <SurveyTemplateSelect placeholder="Szablon badania"/>,
                    )}
                </Form.Item>
                <Form.Item label="Informacja przed badaniem">
                    {getFieldDecorator("informationBeforeSurvey", {rules: [requiredRule]})(
                        <TextArea placeholder="Informacja przed badaniem"/>,
                    )}
                </Form.Item>
                <Form.Item label="Informacja po badaniu">
                    {getFieldDecorator("informationAfterSurvey", {rules: [requiredRule]})(
                        <TextArea placeholder="Informacja po badaniu"/>,
                    )}
                </Form.Item>
                <Form.Item label="Opis dla specjalisty">
                    {getFieldDecorator("description", {rules: [requiredRule]})(
                        <TextArea placeholder="Opis dla specjalisty"/>,
                    )}
                </Form.Item>
            </Form>
        </Spin>
    );
};

const wrapperCol: ColProps = {
    xs: {span: 24},
    sm: {span: 14},
    md: {span: 16},
};

const labelCol: ColProps = {
    xs: {span: 24},
    sm: {span: 10},
    md: {span: 8},
};

const formOptions: FormCreateOption<Props> = {
    onValuesChange(props, diff, values) {
        props.onChange(values);
    },
    mapPropsToFields({data}) {
        return {
            code: Form.createFormField(
                {
                    value: data.code,
                }
            ),
            description: Form.createFormField(
                {
                    value: data.description,
                }
            ),
            informationAfterSurvey: Form.createFormField(
                {
                    value: data.informationAfterSurvey,
                }
            ),
            informationBeforeSurvey: Form.createFormField(
                {
                    value: data.informationBeforeSurvey,
                }
            ),
            name: Form.createFormField(
                {
                    value: data.name,
                }
            ),
            template: Form.createFormField(
                {
                    value: data.template,
                }
            ),
        };
    },
};

export default Form.create(formOptions)(SurveyFormDetails);

export interface SurveyFormDetailsModel {
    name: string;
    code: string;
    informationBeforeSurvey: string;
    informationAfterSurvey: string;
    description: string;
    template: SurveyTemplate;
}
