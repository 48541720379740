import { combineReducers } from "redux";
import { surveyFormDetailsReducer, SurveyFormDetailsState } from "./details/surveyFormDetailsReducer";
import { surveyFormElementsReducer, SurveyFormElementsState } from "./elements/surveyFormElementsReducer";
import surveyFormPublishReducer, { SurveyFormPublishState } from "./publish/surveyFormPublishReducer";
import {
    surveyFormQuestionnairesReducer,
    SurveyFormQuestionnairesState,
} from "./questionnaires/surveyFormQuestionnairesReducer";
import surveyFormSummaryReducer, { SurveyFormSummaryState } from "./summary/surveyFormSummaryReducer";

export interface SurveyFormState {
    details: SurveyFormDetailsState;
    questionnaires: SurveyFormQuestionnairesState;
    elements: SurveyFormElementsState;
    summary: SurveyFormSummaryState;
    publish: SurveyFormPublishState;
}

export const surveyFormReducer = combineReducers(
    {
        details: surveyFormDetailsReducer,
        questionnaires: surveyFormQuestionnairesReducer,
        elements: surveyFormElementsReducer,
        summary: surveyFormSummaryReducer,
        publish: surveyFormPublishReducer,
    },
);
