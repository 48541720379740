import * as React from "react";
import { ComponentType } from "react";
import QuestionnaireType from "../../../resources/questionnaire/QuestionnaireType";
import DefaultQuestionnaireFormContainer from "../defaultQuestionnaireForm/DefaultQuestionnaireFormContainer";
import LikertScaleFormContainer from "../likertScaleForm/LikertScaleFormContainer";
import LikertQuestionnaireFormContainer from "../likertQuestionnaireForm/LikertQuestionnaireFormContainer";
import {Divider} from "antd";

export interface QuestionnaireConfigurationFormStateProps {
    questionnaireId: string;
    questionnaireType: QuestionnaireType;
}

type Props = QuestionnaireConfigurationFormStateProps;

const QuestionnaireConfigurationForm: ComponentType<Props> = ({questionnaireId, questionnaireType}) => {
    switch(questionnaireType) {
        case QuestionnaireType.DEFAULT:
            return <DefaultQuestionnaireFormContainer questionnaireId={questionnaireId}/>;
        case QuestionnaireType.LIKERT_SCALE:
            return <>
                <LikertScaleFormContainer questionnaireId={questionnaireId} />
                <Divider/>
                <LikertQuestionnaireFormContainer questionnaireId={questionnaireId} />
            </>;
        case QuestionnaireType.DEMOGRAPHIC:
                return null;
    }
};

export default QuestionnaireConfigurationForm;
