import { Middleware } from "redux";
import { StoreState } from "../storeReducer";

const storeLogger: Middleware<{}, StoreState> = () => (next) => (action) => {
    console.log("Store action %o with payload %o", action.type, action.payload);

    return next(action);
};

export default storeLogger;
