import { Alert } from "antd";
import { AlertProps } from "antd/lib/alert";
import * as React from "react";
import { ComponentType } from "react";

interface ErrorMessageProps {
    error: any;
    closable?: boolean;
}

const ErrorMessage: ComponentType<ErrorMessageProps> = ({error, closable}) => (
    <Alert type="error" {...getAlertProps(error)} closable={closable}/>
);

const getAlertProps = (error: any): AlertProps => {
    if (null == error || "object" !== typeof error || !error.isAxiosError) {
        return unknownError;
    }

    switch (error.response.status) {
        case 403:
            return error.response.data.errors[0];
        case 401:
            return {message: "Brak dostępu"};
        case 404:
            return {message: "Nie znaleziono"};
    }

    return unknownError;
};

const unknownError = {message: "Wystąpił nieznany błąd applikacji"};

export default ErrorMessage;
