import { Icon, Layout, Menu } from "antd";
import * as React from "react";
import { ComponentType } from "react";
import { Link } from "react-router-dom";

export interface SideMenuStateProps {
    currentPath: string;
    collapsed: boolean;
    isAdmin: boolean;
}

export interface SideMenuDispatchProps {
    onCollapse(collapsed: boolean): void;
}

type Props = SideMenuStateProps & SideMenuDispatchProps;

const SideMenu: ComponentType<Props> = (props) => (
    <Layout.Sider collapsible collapsed={props.collapsed} onCollapse={props.onCollapse}>
        <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[props.currentPath]}
        >
            <Menu.Item key="/">
                <Link to="/">
                    <Icon type="home"/>
                    <span>Strona główna</span>
                </Link>
            </Menu.Item>
            <Menu.Item key="/surveys">
                <Link to="/surveys">
                    <Icon type="file-text"/>
                    <span>Badania</span>
                </Link>
            </Menu.Item>
            {props.isAdmin ? (
            <Menu.Item key="/users">
                <Link to="/users">
                    <Icon type="user"/>
                    <span>Użytkownicy</span>
                </Link>
            </Menu.Item>
            ) : null}
        </Menu>
    </Layout.Sider>
);

export default SideMenu;
