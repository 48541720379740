import {UserDetailsFormStateProps} from "../UserDetailsForm";
import {UserDetailsFormTypes} from "./userDetailsFormTypes";
import {UserEntity} from "../../../resources/UserEntity";

export const userDetailsFormSubmit = (payload: UserDetailsFormStateProps) => ({
    type: UserDetailsFormTypes.SUBMIT,
    payload,
});

export const userDetailsFormSaved = () => ({
    type: UserDetailsFormTypes.SAVED,
});

export const userDetailsFormError = () => ({
   type: UserDetailsFormTypes.ERROR,
});

export const userDetailsFormLoaded = (payload: UserEntity) => ({
  type: UserDetailsFormTypes.LOADED,
  payload,
});

export const userDetailsFormLoad = (userId: string) => ({
  type: UserDetailsFormTypes.LOAD,
  payload: userId,
});

export const userDetailsFormUnload = () => ({
  type: UserDetailsFormTypes.UNLOAD,
});
