import { Dropdown, Icon, Menu } from "antd";
import * as React from "react";
import { ComponentType } from "react";
import { Link } from "react-router-dom";
import UserAvatar from "../userAvatar/UserAvatar";
import UserName from "../UserName";
import styles from "./userMenu.module.scss";

export interface UserMenuStateProps {
    userId: string;
    name: string;
    surname: string;
}

export interface UserMenuDispatchProps {
    logout(): void;
}

type Props = UserMenuStateProps & UserMenuDispatchProps;

const UserMenu: ComponentType<Props> = ({name, surname, userId, logout}) => {
    const menu = (
        <Menu style={{width: 200}}>
            <Menu.Item disabled={true}>
                Witaj <UserName name={name} surname={surname} userId={userId} />
            </Menu.Item>
            <Menu.Divider/>
            <Menu.Item key="/users/edit">
                <Link to={`/users/edit/${userId}`}>
                    <Icon type="edit" /> Edycja profilu
                </Link>
            </Menu.Item>
            <Menu.Item onClick={logout}>
                <Icon type="logout"/> Wyloguj się
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={["click"]}>
            <div className={styles.userMenu}>
                <UserAvatar name={name} surname={surname}/> <Icon type="down"/>
            </div>
        </Dropdown>
    );
};

export default UserMenu;
