import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { StoreState } from "../../store/storeReducer";
import LoginForm, { LoginFormDispatchProps, LogInFormStateProps } from "./LogInForm";
import { logInFormSubmit } from "./store/logInFormActions";

const mapStateToProps: MapStateToProps<LogInFormStateProps, {}, StoreState> = ({logInForm}) => ({
    loading: logInForm.loading,
    error: logInForm.loading ? undefined : logInForm.error,
});

const mapDispatchToProps: MapDispatchToProps<LoginFormDispatchProps, {}> = (dispatch) => ({
    logIn(email: string, password: string, rememberMe: boolean) {
        dispatch(logInFormSubmit(email, password, rememberMe));
    },
});

const LogInFormContainer = connect(mapStateToProps, mapDispatchToProps)(LoginForm);

export default LogInFormContainer;
