import { CurrentUserState } from "./currentUserReducer";
import { CurrentUserTypes } from "./currentUserTypes";

export const loadCurrentUser = (userId: string) => ({
    type: CurrentUserTypes.LOAD,
    payload: userId,
});

export const setCurrentUser = (user: CurrentUserState) => ({
    type: CurrentUserTypes.SET,
    payload: user,
});

export const updateCurrentUser = (user: Partial<CurrentUserState>) => ({
    type: CurrentUserTypes.UPDATE,
    payload: user,
});
