import { ComponentType, default as React } from "react";
import { ConnectDragSource, ConnectDropTarget, DragSource, DropTarget } from "react-dnd";

interface Props extends React.HTMLAttributes<HTMLTableRowElement> {
    isOver: boolean;
    index: number;
    connectDragSource: ConnectDragSource;
    connectDropTarget: ConnectDropTarget;

    moveRow(dragIndex: number, hoverIndex: number): void;
}

const BodyRow: ComponentType<Props> = ({
    isOver,
    connectDragSource,
    connectDropTarget,
    moveRow,
    ...restProps
}) => {
    const style = {...restProps.style, cursor: "move"};

    let className = restProps.className;
    if (isOver) {
        if (restProps.index > dragingIndex) {
            className += " drop-over-downward";
        }
        if (restProps.index < dragingIndex) {
            className += " drop-over-upward";
        }
    }

    return connectDragSource(
        connectDropTarget(
            <tr
                {...restProps}
                className={className}
                style={style}
            />,
        ),
    );
};

const dragSource = DragSource<Props>(
    "row",
    {
        beginDrag({index}) {
            dragingIndex = index;
            return {index};
        },
    },
    (connect) => ({
        connectDragSource: connect.dragSource(),
    }),
)(BodyRow);

const DragableBodyRow = DropTarget<Props>(
    "row",
    {
        drop(props, monitor) {
            const dragIndex = monitor.getItem().index;
            const hoverIndex = props.index;

            if (dragIndex === hoverIndex) {
                return;
            }

            props.moveRow(dragIndex, hoverIndex);
            monitor.getItem().index = hoverIndex;
        },
    },
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
    }),
)(dragSource);

let dragingIndex: number;

export default DragableBodyRow;
