import { Form, Modal } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { Component, CSSProperties } from "react";
import {
    Question,
    QuestionnaireElement,
    QuestionnaireElementType,
} from "../../../resources/questionnaire/QuestionnaireElement";
import generateUuid from "../../../utils/generateUuid";
import QuestionnaireElementModalSteps from "../questionnaireElementModal/QuestionnaireElementModalSteps";
import QuestionElementConfig from "../questionnaireElementModal/QuestionElementConfig";

interface QuestionnaireElementModalStateProps {
    isEdit: boolean;
    defaultValue?: QuestionnaireElement;
    visible: boolean;
    questionnaireId: string;
}

interface QuestionnaireElementModalDispatchProps {
    onCancel(): void;

    onSubmit(model: QuestionnaireElement): void;
}

type OwnProps = QuestionnaireElementModalStateProps & QuestionnaireElementModalDispatchProps;
type Props = FormComponentProps & OwnProps;

class QuestionnaireElementModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            step: 0,
        };
    }

    private static get elementType(): QuestionnaireElementType | undefined {
        return QuestionnaireElementType.QUESTION;
    }

    private static get numberOfSteps() {
        return 1;
    }

    public render() {
        const {visible} = this.props;
        const {step} = this.state;
        const isLastStep = step + 1 === QuestionnaireElementModal.numberOfSteps;

        return (
            <Modal
                width={800}
                align={undefined}
                visible={visible}
                okText={isLastStep ? "Dodaj" : "Dalej"}
                cancelText={step ? "Wróć" : "Anuluj"}
                onCancel={step ? this.prevStep : this.cancel}
                onOk={isLastStep ? this.submitForm : this.nextStep}
                title={this.props.isEdit ? "Edytowanie elementu kwestionariusza" : "Dodawanie elementu do kwestionariusza"}
            >
                <QuestionnaireElementModalSteps
                    current={step}
                    steps={QuestionnaireElementModal.numberOfSteps}
                    onChange={this.changeStep}
                />
                <Form>
                    <div style={this.getStepStyle(0)}>
                        {this.renderStep0Content()}
                    </div>
                </Form>
            </Modal>
        );
    }

    private getStepStyle = (step: number): CSSProperties => ({
        display: step !== this.state.step ? "none" : "block",
    })

    private cancel = () => {
        this.props.onCancel();
        this.props.form.resetFields();
    }

    private submitForm = () => this.props.form.validateFields((err, values) => {
        if (err) {
            return;
        }

        const element = this.getElementFromValues(values);
        this.setState({step: 0});
        this.props.form.resetFields();
        this.props.onSubmit(element);
    })

    private getElementFromValues = (values: Partial<Question>): QuestionnaireElement => {
        const {questionnaireId} = this.props;
        const elementId = this.props.isEdit && !!this.props.defaultValue ? this.props.defaultValue.elementId : generateUuid();

        const element: Question = {
            type: QuestionnaireElementType.QUESTION,
            elementId,
            questionnaireId,
            required: values.required || false,
            private: values.private || false,
            description: values.description || "",
            answerType: null,
        };

        return element as QuestionnaireElement;
    }

    private nextStep = () => {
        this.props.form.validateFields((err) => {
            if (!err) {
                this.setState(({step}) => ({
                    step: Math.min(QuestionnaireElementModal.numberOfSteps, step + 1),
                }));
            }
        });
    }

    private prevStep = () => this.setState(({step}) => ({
        step: Math.max(0, step - 1),
    }))

    private changeStep = (step: number) => {
        if (step < this.state.step) {
            this.setState({step});
        }
    }

    private renderStep0Content = () => {
        const {form} = this.props;

        switch (QuestionnaireElementModal.elementType) {
            case QuestionnaireElementType.QUESTION: {
                return <QuestionElementConfig form={form}/>;
            }
            default:
                return null;
        }
    }
}

interface State {
    step: number;
}

export default Form.create<Props>({
    onValuesChange: (props, changedValues, allValues) => {
        console.log(props);
        console.log(changedValues);
        console.log(allValues);
    },
    mapPropsToFields: (props) => {
        if (!!props.defaultValue) {
            if (QuestionnaireElementType.QUESTION === props.defaultValue.type) {
                return {
                    questionnaireId: Form.createFormField({value: props.defaultValue.questionnaireId}),
                    elementId: Form.createFormField({value: props.defaultValue.elementId}),
                    type: Form.createFormField({value: props.defaultValue.type}),
                    private: Form.createFormField({value: props.defaultValue.private}),
                    required: Form.createFormField({value: props.defaultValue.required}),
                    description: Form.createFormField({value: props.defaultValue.description}),
                    answerType: Form.createFormField({value: null}),
                }
            }

            throw new Error("Invalid questionnaire element type.");
        } else {
            return {};
        }
    },
})(QuestionnaireElementModal);
