const configuration: Configuration = {
    version: process.env.APP_VERSION as string,
    debug: process.env.DEBUG,
    apiEndpoint: "/api"
};

interface Configuration {
    apiEndpoint: string;
    debug?: string;
    version: string;
}

export default configuration;
