import * as React from "react";
import {ComponentType, useEffect} from "react";
import Helmet from "react-helmet";
import PanelLayout from "./panelLayout/PanelLayout";
import UserDetailsFormContainer from "./userDetailsForm/UserDetailsFormContainer";
import {useDispatch} from "react-redux";
import {userDetailsFormLoad, userDetailsFormUnload} from "./userDetailsForm/store/userDetailsFormActions";

export interface EditUserPageStateProps {
  userId?: string;
}
type Props = EditUserPageStateProps;
const EditUserPage: ComponentType<Props> = ({userId}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userId) {
      return;
    }
    dispatch(userDetailsFormLoad(userId));

    return () => {
      dispatch(userDetailsFormUnload());
    };
  }, [dispatch, userId]);

  return (
      <PanelLayout>
          <Helmet>
              <title>Dane użytkownika</title>
          </Helmet>
          <UserDetailsFormContainer/>
      </PanelLayout>
  );
};

export default EditUserPage;
