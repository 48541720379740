import { connect, MapStateToProps } from "react-redux";
import { StoreState } from "../../../store/storeReducer";
import SurveyFormSummary, { SurveyFormSummaryStateProps } from "./SurveyFormSummary";

interface OwnProps {
    surveyId: string;
}

const mapStateToProps: MapStateToProps<SurveyFormSummaryStateProps, OwnProps, StoreState> = (
    {surveyForm: {summary}}
) => ({
    validCode: summary.data.validCode,
    validDescription: summary.data.validDescription,
    validName: summary.data.validName,
});

export default connect(mapStateToProps)(SurveyFormSummary);
