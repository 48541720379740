import * as React from "react";
import { ComponentType } from "react";

interface UserNameProps {
    userId: string;
    name: string;
    surname: string;
}

const UserName: ComponentType<UserNameProps> = ({name, surname}) => (
    <>{surname} {name}</>
);

export default UserName;
