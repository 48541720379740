import {QuestionnaireElement} from "../../../resources/questionnaire/QuestionnaireElement";
import * as React from "react";
import {Button} from "antd";
import LikertQuestionnaireElementModal from "../likertQuestionnaireElementModal/LikertQuestionnaireElementModal";
import LikertQuestionnaireElementsContainer from "../likertQuestionnaireElements/LikertQuestionnaireElementsContainer";

export interface LikertQuestionnaireFormStateProps {
  questionnaireId: string;
  elements: QuestionnaireElement[];
}

export interface LikertQuestionnaireFormDispatchProps {
  updateElement(element: QuestionnaireElement): void;
  addElement(element: QuestionnaireElement): void;
}

type Props = LikertQuestionnaireFormStateProps & LikertQuestionnaireFormDispatchProps;

interface State {
  modal: boolean;
}

export default class DefaultQuestionnaireForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modal: false,
    };
  }
  public render() {
    return (
      <div>
        <LikertQuestionnaireElementsContainer
          questionnaireId={this.props.questionnaireId}
        />
        <LikertQuestionnaireElementModal
          isEdit={false}
          questionnaireId={this.props.questionnaireId}
          visible={this.state.modal}
          onSubmit={this.addElement}
          onCancel={this.hideModal}
        />
        <Button
          htmlType="button"
          type="default"
          icon="plus"
          onClick={this.showModal}
          style={{marginTop: 20}}
        >
          Dodaj element
        </Button>
      </div>
    );
  }

  private showModal = () => this.setState({modal: true});

  private hideModal = () => this.setState({modal: false});

  private addElement = (element: QuestionnaireElement) => {
    this.hideModal();
    this.props.addElement(element);
  }
}
