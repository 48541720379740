import { push } from "connected-react-router";
import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { StoreState } from "../../store/storeReducer";
import EditSurveyButton, { EditSurveyButtonDispatchProps, EditSurveyButtonStateProps } from "./EditSurveyButton";

interface OwnProps {
    surveyId: string,
}

const mapStateToProps: MapStateToProps<EditSurveyButtonStateProps, OwnProps, StoreState> = ({surveyList}, {surveyId}) => ({
  surveyId,
  loading: surveyList.loadingItemIds.findIndex((uuid) => uuid === surveyId) > -1,
});
const mapDispatchToProps: MapDispatchToProps<EditSurveyButtonDispatchProps, OwnProps> = (dispatch, {surveyId}) => ({
  onClick() {
    dispatch(push(`/surveys/${surveyId}/edit/0`));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSurveyButton);
