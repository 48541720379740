import axios from "axios";
import { AnyAction } from "redux";
import { call, put, select, takeEvery } from "redux-saga/effects";
import {
    QuestionnaireElement
} from "../../../../resources/questionnaire/QuestionnaireElement";
import { loadCurrentSurveyForm } from "../surveyFormActions";
import { SurveyFormType } from "../surveyFormTypes";
import {
    addQuestionnaireElementToSurveyForm,
    removeQuestionnaireElementFromSurveyForm,
    setSurveyFormElements,
    sortQuestionnaireElementsInSurveyForm,
    updateQuestionnaireElementInSurveyForm,
} from "./surveyFormElementsActions";
import { SurveyFormElementsType } from "./surveyFormElementsTypes";
import {ErrorTypes} from "../../../error/store/errorTypes";

function* surveyFormElementsSaga() {
    yield takeEvery(SurveyFormElementsType.ADD, addQuestionnaireElementSaga);
    yield takeEvery(SurveyFormElementsType.UPDATE, sendQuestionnaireElementSaga);
    yield takeEvery(SurveyFormElementsType.REMOVE, removeQuestionnaireElementSaga);
    yield takeEvery(
        (event: AnyAction) => event.type === SurveyFormType.LOAD && event.payload.step === 1,
        loadCurrentSurveySaga,
    );
    yield takeEvery(SurveyFormElementsType.SORT, sortElementsSaga);
}

function* removeQuestionnaireElementSaga({payload}: ReturnType<typeof removeQuestionnaireElementFromSurveyForm>) {
    try {
        yield call(axios.delete, `/elements/${payload}`);
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot remove questionnaire element.", e);
    }
}

function* sortElementsSaga({payload}: ReturnType<typeof sortQuestionnaireElementsInSurveyForm>) {
    try {
        const state = yield select();

        const questionnaireId = payload.questionnaireId;
        const elements = state.surveyForm.elements.filter((value: QuestionnaireElement) => value.questionnaireId === questionnaireId);

        yield call(axios.patch, `/questionnaires/${questionnaireId}/elements/sort`, {
            draggedIds: elements.map((value: QuestionnaireElement) => value.elementId),
        });

    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot save questionnaire.", e);
    }
}

function* addQuestionnaireElementSaga({payload}: ReturnType<typeof addQuestionnaireElementToSurveyForm>) {
    const {questionnaireId} = payload;
    try {
        yield call(axios.post, `/questionnaires/${questionnaireId}/elements`, {element: payload});
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot create questionnaire element.", e);
    }
}

function* loadCurrentSurveySaga({payload: {surveyId}}: ReturnType<typeof loadCurrentSurveyForm>) {
    try {
        const response = yield call(axios.get, `/surveys/${surveyId}/elements`);
        yield put(setSurveyFormElements(response.data.elements));
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot load survey questionnaires elements.", e);
    }
}

function* sendQuestionnaireElementSaga({payload}: ReturnType<typeof updateQuestionnaireElementInSurveyForm>) {
    try {
        yield call(axios.put, `/elements/${payload.elementId}`, {questionnaireElement: payload});
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot update questionnaire element.", e);
    }
}

export default surveyFormElementsSaga;
