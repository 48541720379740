import { Reducer } from "redux";
import { SurveyFormType } from "../surveyFormTypes";
import { SurveyFormPublishTypes } from "./surveyFormPublishTypes";

export interface SurveyFormPublishState {
    publishing: boolean;
}

const initialState: SurveyFormPublishState = {
    publishing: false,
};

const surveyFormPublishReducer: Reducer<SurveyFormPublishState> = (state = initialState, action) => {
    switch (action.type as SurveyFormPublishTypes | SurveyFormType) {
        case SurveyFormType.RESET:
        case SurveyFormType.LOAD:
            return initialState;
        case SurveyFormPublishTypes.PUBLISH:
            return {publishing: true};
    }

    return state;
};

export default surveyFormPublishReducer;
