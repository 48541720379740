import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import PreviewSurveyButton, {PreviewSurveyButtonDispatchProps, PreviewSurveyButtonStateProps} from "./PreviewSurveyButton";
import {StoreState} from "../../store/storeReducer";
import {previewSurveyForm} from "../surveyList/store/surveyListActions";

interface OwnProps {
  surveyId: string,
}

const mapStateToProps: MapStateToProps<PreviewSurveyButtonStateProps, OwnProps, StoreState> = ({surveyList}, {surveyId}) => ({
  surveyId,
  loading: surveyList.loadingItemIds.findIndex((uuid) => uuid === surveyId) > -1,
});

const mapDispatchToProps: MapDispatchToProps<PreviewSurveyButtonDispatchProps, OwnProps> = (dispatch, {surveyId}) => ({
  onClick() {
    dispatch(previewSurveyForm(surveyId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewSurveyButton);
