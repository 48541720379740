import { connect, MapStateToProps } from "react-redux";
import { StoreState } from "../../../store/storeReducer";
import { getQuestionnaireFromSurveyForm } from "../store/questionnaires/surveyFormQuestionnairesSelectors";
import QuestionnaireConfigurationForm, {
    QuestionnaireConfigurationFormStateProps as StateProps,
} from "./QuestionnaireConfigurationForm";

interface OwnProps {
    questionnaireId: string;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, StoreState> = (state, {questionnaireId}) => ({
    questionnaireId,
    questionnaireType: getQuestionnaireFromSurveyForm(questionnaireId)(state).type,
});

export default connect(mapStateToProps)(QuestionnaireConfigurationForm);
