import { message } from "antd";
import axios from "axios";
import { push } from "connected-react-router";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";
import { SurveyStatus } from "../../../../resources/SurveyEntity";
import { LoadCurrentSurveyFormAction } from "../surveyFormActions";
import { SurveyFormType } from "../surveyFormTypes";
import { loadSurveyFormSummary, setSurveyFormSummary } from "./surveyFormSummaryActions";
import {ErrorTypes} from "../../../error/store/errorTypes";

function* surveyFormSummarySaga() {
    yield takeLatest(
        (event: AnyAction) => event.type === SurveyFormType.LOAD && event.payload.step === 2,
        loadSummarySaga
    );
}

function* loadSummarySaga({payload: {surveyId}}: LoadCurrentSurveyFormAction) {
    try {
        yield put(loadSurveyFormSummary(surveyId));
        const {data: {status, details}} = yield call(axios.get, `surveys/${surveyId}/status`);
        if (status !== SurveyStatus.DRAFT) {
            yield call(message.error,"Badanie zostało opublikowane już wcześniej");
            yield put(push("/surveys"));
        } else {
            yield put(setSurveyFormSummary(details));
        }
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot load survey summary.", e);
    }
}

export default surveyFormSummarySaga;
