import { AnswerType } from "./AnswerType";

export type QuestionnaireElement = TextElement | Question;
export interface LikertScale {
    elements: LikertScaleElement[];
}

export interface TextElement extends AbstractQuestionnaireElement {
    type: QuestionnaireElementType.TEXT;
    text: string;
}

export interface Question extends  AbstractQuestionnaireElement {
    type: QuestionnaireElementType.QUESTION;
    private: boolean;
    required: boolean;
    description: string;
    answerType: null|AnswerType;
}

export interface LikertScaleElement{
    description: string;
}

interface AbstractQuestionnaireElement {
    questionnaireId: string;
    elementId: string;
    type: QuestionnaireElementType;
}

export enum QuestionnaireElementType {
    TEXT = "text",
    QUESTION = "question",
}
