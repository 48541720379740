import { Form } from "antd";
import { FormComponentProps } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import { ComponentType, default as React } from "react";
import { requiredRule } from "../../../utils/validationRules";

type Props = FormComponentProps;

export const TextElementConfig: ComponentType<Props> = (props) => (
    <Form.Item label="Tekst do wyświetlenia">
        {props.form.getFieldDecorator("text", {
            rules: [requiredRule],
        })(
            <TextArea placeholder="Tekst do wyświetlenia"/>,
        )}
    </Form.Item>
);

export default TextElementConfig;
