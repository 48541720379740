import { Alert, Button } from "antd";
import * as React from "react";
import { createVoidQuestionnaire, Questionnaire } from "../../../resources/questionnaire/Questionnaire";
import QuestionnaireList from "../../questionnaireList/QuestionnaireList";
import {
    default as QuestionnaireConfigurationFormContainer,
} from "../questionnaireConfigurationForm/QuestionnaireConfigurationFormContainer";
import QuestionnaireDetailsModal from "../questionnaireDetailsModal/QuestionnaireDetailsModal";
import QuestionnaireItemOptionsContainer from "../questionnaireOptions/QuestionnaireOptionsContainer";
import styles from "./surveyFormQuestionnaires.module.scss";

export interface SurveyFormQuestionnairesStateProps {
    questionnaires: Questionnaire[];
}

export interface SurveyFormQuestionnairesDispatchProps {
    createQuestionnaire(questionnaire: Questionnaire): void;

    sortQuestionnaires(dragIndex: number, hoverIndex: number): void;
}

type Props = SurveyFormQuestionnairesStateProps & SurveyFormQuestionnairesDispatchProps;

class SurveyFormQuestionnaires extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {modal: false};
    }

    public render() {
        return (
            <>
                <QuestionnaireDetailsModal
                    defaultValue={createVoidQuestionnaire()}
                    visible={this.state.modal}
                    onCancel={this.hideModal}
                    onSubmit={this.createQuestionnaire}
                    isEdit={false}
                />
                <QuestionnaireList
                    items={this.props.questionnaires}
                    options={QuestionnaireItemOptionsContainer}
                    onDragSorting={this.props.sortQuestionnaires}
                    expandedRow={QuestionnaireConfigurationFormContainer}
                />
                <Button
                    htmlType="button"
                    type="default"
                    icon="plus"
                    onClick={this.showModal}
                    className={styles.addButton}
                >
                    Dodaj kwestionariusz
                </Button>
                <Alert
                    type="info"
                    className={styles.tip}
                    message="Wskazówka"
                    showIcon={true}
                    description={
                        `W celu zmiany kolejności kwestionariuszy lub elementów kwestionariusza należy klinąć wybrane
                        element i przeciągnać go w oczekiwane miejsce`}
                />
            </>
        );
    }

    private hideModal = () => this.setState({modal: false});

    private showModal = () => this.setState({modal: true});

    private createQuestionnaire = (questionnaire: Questionnaire) => {
        this.props.createQuestionnaire(questionnaire);
        this.hideModal();
    }
}

interface State {
    modal: boolean;
}

export default SurveyFormQuestionnaires;
