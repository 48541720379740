import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import { logInFormReducer, LogInFormState } from "../components/logInForm/store/logInFormReducer";
import { surveyFormReducer, SurveyFormState } from "../components/surveyForm/store/surveyFormReducer";
import { surveyListReducer, SurveyListState } from "../components/surveyList/store/surveyListReducer";
import {
    userDetailsFormReducer,
    UserDetailsFormState,
} from "../components/userDetailsForm/store/userDetailsFormReducer";
import { userListReducer, UserListState } from "../components/userList/store/userListReducer";
import { currentUserReducer, CurrentUserState } from "./currentUser/currentUserReducer";
import { sessionReducer, SessionState } from "./session/sessionReducer";
import { sideMenuReducer, SideMenuState } from "./sideMenu/sideMenuReducer";

export default (history: History) => combineReducers({
    router: connectRouter(history),
    session: sessionReducer,
    logInForm: logInFormReducer,
    currentUser: currentUserReducer,
    sideMenu: sideMenuReducer,
    surveyList: surveyListReducer,
    userList: userListReducer,
    surveyForm: surveyFormReducer,
    userDetailsForm: userDetailsFormReducer,
});

export interface StoreState {
    router: RouterState;
    session: SessionState;
    logInForm: LogInFormState;
    currentUser: CurrentUserState;
    sideMenu: SideMenuState;
    surveyList: SurveyListState;
    userList: UserListState;
    surveyForm: SurveyFormState;
    userDetailsForm: UserDetailsFormState;
}
