import { Button } from "antd";
import * as React from "react";
import { ComponentType, useCallback, useState } from "react";
import { LikertScaleElement } from "../../../resources/questionnaire/QuestionnaireElement";
import LikertScaleElementModal from "../LikertScaleElementModal/LikertScaleElementModal";
import LikertScaleElementsContainer from "../likertScaleElements/LikertScaleElementsContainer";

export interface LikertScaleFormStateProps {
    questionnaireId: string;
    elements: LikertScaleElement[];
}

export interface LikertScaleFormDispatchProps {
    updateElements(elements: LikertScaleElement[]): void;
}

type Props = LikertScaleFormStateProps & LikertScaleFormDispatchProps;

const LikertScaleForm: ComponentType<Props> = (
    {
        questionnaireId,
        elements,
        updateElements,
    }
) => {
    const [modalVisible, setModalVisibility] = useState(false);
    const showModal = useCallback(() => setModalVisibility(true), [setModalVisibility]);
    const hideModal = useCallback(() => setModalVisibility(false), [setModalVisibility]);
    const addElementAndHideModal = useCallback((element: LikertScaleElement) => {
        updateElements([...elements, element]);
        setModalVisibility(false);
    }, [setModalVisibility, updateElements, elements]);

    return (
        <div>
            <LikertScaleElementsContainer
              questionnaireId={questionnaireId}
            />
            <LikertScaleElementModal
                visible={modalVisible}
                questionnaireId={questionnaireId}
                onSubmit={addElementAndHideModal}
                onCancel={hideModal}
            />
            <Button
              htmlType="button"
              icon="plus"
              onClick={showModal}
              style={{marginTop: 20, marginBottom: 10}}
            >
                Dodaj element skali
            </Button>
        </div>
    );
};

export default LikertScaleForm;
