import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { StoreState } from "../../../store/storeReducer";
import LikertScaleForm, { LikertScaleFormDispatchProps, LikertScaleFormStateProps } from "./LikertScaleForm";
import {updateQuestionnaireScaleInSurveyForm} from "../store/questionnaires/surveyFormQuestionnairesActions";
import {getQuestionnaireFromSurveyForm} from "../store/questionnaires/surveyFormQuestionnairesSelectors";

interface OwnProps {
    questionnaireId: string;
}

const mapStateToProps: MapStateToProps<LikertScaleFormStateProps, OwnProps, StoreState> = (state, props) => {
    const questionnaire = getQuestionnaireFromSurveyForm(props.questionnaireId)(state);

    return {
        elements: questionnaire.scale ? questionnaire.scale.elements : [],
        questionnaireId: props.questionnaireId,
    }
};

const mapDispatchToProps: MapDispatchToProps<LikertScaleFormDispatchProps, OwnProps> = (dispatch, props) => ({
    updateElements(elements) {
        dispatch(updateQuestionnaireScaleInSurveyForm({
            questionnaireId: props.questionnaireId,
            elements: elements,
        }));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LikertScaleForm);
