import { Button } from "antd";
import * as React from "react";
import { QuestionnaireElement } from "../../../resources/questionnaire/QuestionnaireElement";
import QuestionnaireElementModal from "../questionnaireElementModal/QuestionnaireElementModal";
import QuestionnaireElementsContainer from "../questionnaireElements/QuestionnaireElementsContainer";

export interface DefaultQuestionnaireFormStateProps {
    elements: QuestionnaireElement[];
    questionnaireId: string;
}

export interface DefaultQuestionnaireFormDispatchProps {
    updateElement(element: QuestionnaireElement): void;
    addElement(element: QuestionnaireElement): void;
}

type Props = DefaultQuestionnaireFormStateProps & DefaultQuestionnaireFormDispatchProps;

export default class DefaultQuestionnaireForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            modal: false,
        };
    }
    public render() {
        return (
            <>
                <QuestionnaireElementsContainer
                    questionnaireId={this.props.questionnaireId}
                />
                <QuestionnaireElementModal
                    isEdit={false}
                    questionnaireId={this.props.questionnaireId}
                    visible={this.state.modal}
                    onSubmit={this.addElement}
                    onCancel={this.hideModal}
                />
                <Button
                    htmlType="button"
                    type="default"
                    icon="plus"
                    onClick={this.showModal}
                    style={{marginTop: 20}}
                >
                    Dodaj element
                </Button>
            </>
        );
    }

    private showModal = () => this.setState({modal: true});

    private hideModal = () => this.setState({modal: false});

    private addElement = (element: QuestionnaireElement) => {
        this.hideModal();
        this.props.addElement(element);
    }
}

interface State {
    modal: boolean;
}
