import { Select } from "antd";
import { SelectProps } from "antd/lib/select";
import * as React from "react";
import { ComponentType } from "react";
import { AnswerTypeValue, TextAnswerValueType } from "../../resources/questionnaire/AnswerType";

const {Option} = Select;

type AnswerTypeSelectProps = SelectProps<TextAnswerValueType>;

const AnswerTypeSelect: ComponentType<AnswerTypeSelectProps> = (props, ref?: any) => (
    <Select ref={ref} {...props}>
        <Option value={AnswerTypeValue.TEXT}>Otwarty tekst</Option>
        <Option value={AnswerTypeValue.ANALOGUE}>Skala analogowa</Option>
        <Option value={AnswerTypeValue.SIMPLE}>Lista wyboru</Option>
    </Select>
);

export default  React.forwardRef(AnswerTypeSelect);
