import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { StoreState } from "../../../store/storeReducer";
import {
    removeQuestionnaireElementFromSurveyForm,
    sortQuestionnaireElementsInSurveyForm,
    updateQuestionnaireElementInSurveyForm,
} from "../store/elements/surveyFormElementsActions";
import { getQuestionnaireElements } from "../store/elements/surveyFormElementsSelectors";
import LikertQuestionnaireElements, {
    LikertQuestionnaireElementsDispatchProps as DispatchProps,
    LikertQuestionnaireElementsStateProps as StateProps,
} from "./LikertQuestionnaireElements";

interface OwnProps {
    questionnaireId: string;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, StoreState> = (state, props) => ({
    items: getQuestionnaireElements(props.questionnaireId)(state),
});

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch, props) => ({
    remove(elementId) {
        dispatch(removeQuestionnaireElementFromSurveyForm(elementId));
    },
    update(element) {
        dispatch(updateQuestionnaireElementInSurveyForm(element));
    },
    sort(dragIndex, hoverIndex) {
        dispatch(sortQuestionnaireElementsInSurveyForm(dragIndex, hoverIndex, props.questionnaireId));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(LikertQuestionnaireElements);
