import * as React from "react";
import { ComponentType } from "react";
import SurveyFormNextButtonContainer from "../surveyFormFlowControls/SurveyFormFlowControlsContainer";
import surveyFormSteps from "../surveyFormSteps/surveyFormSteps";
import styles from "./surveyFormStep.module.scss";

export interface SurveyFormStepStateProps {
    surveyId: string;
    step: number;
}

type Props = SurveyFormStepStateProps;

const SurveyFormStep: ComponentType<Props> = ({surveyId, step}) => {
    const StepComponent = surveyFormSteps[step].component;
    return (
        <div className={styles.wrapper}>
            <div className={styles.step}>
                <StepComponent surveyId={surveyId}/>
            </div>
            <SurveyFormNextButtonContainer surveyId={surveyId} step={step}/>
        </div>
    );
};

export default SurveyFormStep;
