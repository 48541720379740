import { ComponentType } from "react";
import SurveyFormDetailsContainer from "../surveyFormDetails/SurveyFormDetailsContainer";
import SurveyFormQuestionnairesContainer from "../surveyFormQuestionnaires/SurveyFormQuestionnairesContainer";
import SurveyFormSummaryContainer from "../surveyFormSummary/SurveyFormSummaryContainer";

interface SurveyFormStep {
    component: ComponentType<{surveyId: string}>;
    title: string;
}

const surveyFormSteps: SurveyFormStep[] = [
    {
        title: "Podstawowe dane",
        component: SurveyFormDetailsContainer,
    },
    {
        title: "Kwestionariusze",
        component: SurveyFormQuestionnairesContainer,
    },
    {
        title: "Podsumowanie",
        component: SurveyFormSummaryContainer,
    },
];

export default surveyFormSteps;
