import { Button, Modal } from "antd";
import * as React from "react";
import { Questionnaire } from "../../../resources/questionnaire/Questionnaire";
import QuestionnaireDetailsModal from "../questionnaireDetailsModal/QuestionnaireDetailsModal";

export interface QuestionnaireOptionsStateProps {
    questionnaire: Questionnaire;
}

export interface QuestionnaireOptionsDispatchProps {
    onDelete(): void;

    onEdit(questionnaire: Questionnaire): void;
}

type Props = QuestionnaireOptionsStateProps & QuestionnaireOptionsDispatchProps;

class QuestionnaireOptions extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {modal: false};
    }

    public render() {
        return (
            <Button.Group>
                <QuestionnaireDetailsModal
                    defaultValue={this.props.questionnaire}
                    onSubmit={this.onEditSubmit}
                    onCancel={this.closeEditModal}
                    visible={this.state.modal}
                    isEdit={true}
                />
                <Button
                    htmlType="button"
                    type="default"
                    onClick={this.showEditModal}
                    icon="edit"
                >
                    Edytuj
                </Button>
                <Button
                    htmlType="button"
                    type="danger"
                    onClick={this.onDelete}
                    icon="delete"
                >
                    Usuń
                </Button>
            </Button.Group>
        );
    }
    private onDelete = () => Modal.confirm(
        {
            title: "Czy jesteś pewny?",
            icon: "delete",
            content: (
                <>
                    Właśnie kasujesz kwestionariusz "<b>{this.props.questionnaire.name}</b>".
                </>
            ),
            autoFocusButton: "cancel",
            okText: "Usuń",
            okType: "danger",
            okButtonProps: {icon: "delete"},
            onOk: this.props.onDelete,
        },
    )

    private onEditSubmit = (model: Questionnaire) => {
        this.setState({modal: false});
        this.props.onEdit(model);
    }

    private closeEditModal = () => this.setState({modal: false});

    private showEditModal = () => this.setState({modal: true});
}

interface State {
    modal: boolean;
}

export default QuestionnaireOptions;
