import * as React from "react";
import Helmet from "react-helmet";
import BlankLayout from "./blankLayout/BlankLayout";
import LogInFormContainer from "./logInForm/LogInFormContainer";

const LoginPage = () => (
    <BlankLayout>
        <Helmet>
            <title>Logowanie</title>
        </Helmet>
        <LogInFormContainer />
    </BlankLayout>
);

export default LoginPage;
