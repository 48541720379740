import { ValidationRule } from "antd/lib/form";

export const requiredRule: ValidationRule = {required: true, message: "To pole jest wymagane"};
export const emailRule: ValidationRule = {type: "email", message: "Podaj poprawny adres e-mail"};
export const maxRule = (max: number): ValidationRule => ({
    validator: (rule, value, callback) => value > max
        ? callback(`Maksymalna wartość wynosi ${max}`)
        : callback(),
});
export const minRule = (min: number): ValidationRule => ({
    validator: (rule, value, callback) => value < min
        ? callback(`Minimalna wartość wynosi ${min}`)
        : callback(),
});
