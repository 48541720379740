import Pagination from "../utils/pagination";

export interface SurveyEntity {
    id: string;
    name: string;
    code: string;
    status: SurveyStatus;
}

export enum SurveyStatus {
    PUBLISHED = "published",
    DRAFT = "draft",
}

export enum AnonymousTag {
    NOT_APPLICABLE = "not_applicable",
    WITH_PERSONAL_DATA = "with_personal_data",
    ANONYMIZED_VERSION = "anonymized_version",
}

export type SurveyFilter = Pagination;
