import { fork, takeLatest } from "redux-saga/effects";
import surveyFormDetailsSaga from "./details/surveyFormDetailsSaga";
import surveyFormElementsSaga from "./elements/surveyFormElementsSaga";
import surveyFormPublishSaga from "./publish/surveyFormPublishSaga";
import { SurveyFormPublishTypes } from "./publish/surveyFormPublishTypes";
import surveyFormQuestionnairesSaga from "./questionnaires/surveyFormQuestionnairesSaga";
import surveyFormSummarySaga from "./summary/surveyFormSummarySaga";

function* surveyFormSaga() {
    yield fork(surveyFormDetailsSaga);
    yield fork(surveyFormQuestionnairesSaga);
    yield fork(surveyFormElementsSaga);
    yield fork(surveyFormSummarySaga);
    yield takeLatest(SurveyFormPublishTypes.PUBLISH, surveyFormPublishSaga);
}

export default surveyFormSaga;
