export type AnswerType = SimpleAnswerType | AnalogueAnswerType | TextAnswerType;

export interface AnalogueAnswerType {
    answerTypeId: string;
    type: AnswerTypeValue.ANALOGUE;
    step: number;
    maxValue: number;
    minValue: number;
    leftDescription: string;
    rightDescription: string;
}

export interface TextAnswerType {
    answerTypeId: string;
    type: AnswerTypeValue.TEXT;
    valueType: TextAnswerValueType;
}

export interface SimpleAnswerType {
    answerTypeId: string;
    type: AnswerTypeValue.SIMPLE;
    maxCount: number;
    minCount: number;
    namedAnswers: NamedAnswer[];
}

export enum AnswerTypeValue {
    TEXT = "text",
    SIMPLE = "simple",
    ANALOGUE = "analogueScale",
}

export enum TextAnswerValueType {
    DEFAULT = "default",
    PESEL = "pesel",
    ICD10 = "icd10",
    DATE = "date",
    TEXTAREA = "text",
    PHONE = "phone",
}

export interface NamedAnswer {
    value: number;
    displayedValue?: string;
    description: string;
}
