import * as React from "react";
import {Button} from "antd";
import {ComponentType} from "react";

export interface PreviewSurveyButtonDispatchProps {
  onClick(): void;
}

export interface PreviewSurveyButtonStateProps {
  surveyId: string,
  loading: boolean,
}

type Props = PreviewSurveyButtonDispatchProps & PreviewSurveyButtonStateProps;

const PreviewSurveyButton: ComponentType<Props> = (props) => {
  return (
    <Button
      htmlType="button"
      icon="form"
      onClick={props.onClick}
      disabled={props.loading}
      loading={props.loading}
    >
      Podgląd
    </Button>
  );
};

export default PreviewSurveyButton;
