import * as React from "react";
import PanelLayout from "./panelLayout/PanelLayout";

const DashboardPage = () => (
    <PanelLayout>
        Hello world
    </PanelLayout>
);

export default DashboardPage;
