import { AnyAction, Reducer } from "redux";
import { SurveyListItem } from "../../../resources/survey/List/SurveyListItem";
import { SurveyStatus } from "../../../resources/SurveyEntity";
import { publishedSurveyForm, publishSurveyForm, readySurveyList } from "./surveyListActions";
import { SurveyListType } from "./SurveyListType";

export interface SurveyListState {
    loadingItemIds: string[],
    loading: boolean;
    items: SurveyListItem[];
    total: number;
}

const surveyListInitialState: SurveyListState = {
    loading: false,
    loadingItemIds: [],
    items: [],
    total: 0,
};

export const surveyListReducer: Reducer<SurveyListState> = (
  state: SurveyListState = surveyListInitialState,
  action: AnyAction
) => {
    switch (action.type as SurveyListType | any) {
        case SurveyListType.RESET:
            return surveyListInitialState;
        case SurveyListType.LOAD:
            return {
                ...state,
                loading: true,
            };
        case SurveyListType.READY:
            const {payload: {total, items}} = action as ReturnType<typeof readySurveyList>;
            return {
                ...state,
                items,
                total,
                loading: false,
                loadingItemIds: [],
            };
        case SurveyListType.PUBLISH: {
            //add to loading items - show spinner and disable
            const {payload} = action as ReturnType<typeof publishSurveyForm>;

            return {
                ...state,
                loadingItemIds: state.loadingItemIds.concat([payload]),
            };
        }
        case  SurveyListType.PUBLISHED: {
            //remove from loading items, change status to change buttons
            const {payload} = action as ReturnType<typeof publishedSurveyForm>;
            const surveyId = payload.toString();

            return {
                ...state,
                loadingItemIds: state.loadingItemIds.filter((uuid) => uuid !== payload),
                items: state.items.map((item) => item.id === surveyId ? {
                    ...item,
                    status: SurveyStatus.PUBLISHED,
                } : item),
            };
        }
    }

    return state;
};
