import axios from "axios";
import { call, put, takeLatest } from "redux-saga/effects";
import { loadUserList, readyUserList } from "./userListActions";
import { UserListTypes } from "./userListTypes";
import {ErrorTypes} from "../../error/store/errorTypes";

function* userListSaga() {
    yield takeLatest(UserListTypes.LOAD, loadUserListSaga);
}

function* loadUserListSaga({payload: {page, pageSize}}: ReturnType<typeof loadUserList>) {
    const take = pageSize;
    const skip = (page - 1) * pageSize;
    try {
        const {data: {items, total}} = yield call(axios.get, "users", {params: {skip, take}});
        yield put(readyUserList(items, total));
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot load users.", e);
    }
}

export default userListSaga;
