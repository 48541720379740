import { LogInFormTypes } from "./logInFormTypes";

export const logInFormSubmit = (email: string, password: string, remember: boolean) => ({
    type: LogInFormTypes.SUBMIT,
    payload: {email, password, remember},
});

export const logInFormError = (error: any) => ({
    type: LogInFormTypes.ERROR,
    payload: error,
});

export const logInFormReset = () => ({
    type: LogInFormTypes.RESET,
});
