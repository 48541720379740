import {Empty, Table} from "antd";
import { ColumnProps, TableProps } from "antd/lib/table";
import * as React from "react";
import { ComponentType } from "react";
import { Questionnaire } from "../../resources/questionnaire/Questionnaire";
import DragSortingTable from "../dragSortingTable/DragSortingTable";
import QuestionnaireTypeName from "../QuestionnaireTypeName";

interface QuestionnaireListStateProps {
    items: Questionnaire[];
    options: ComponentType<{ questionnaireId: string }>;
    expandedRow?: ComponentType<{ questionnaireId: string }>;
}

interface QuestionnaireListDispatchProps {
    onDragSorting?(dragIndex: number, hoverIndex: number): void;
}

type Props = QuestionnaireListStateProps & QuestionnaireListDispatchProps;

const QuestionnaireList: ComponentType<Props> = (
    {
        items,
        expandedRow,
        options,
        onDragSorting,
    },
) => {
    const RowOptions = options;
    const columns: Array<ColumnProps<Questionnaire>> = [
        {
            key: "index",
            title: "L.p.",
            render: (n, q, i) => <>{i + 1}.</>,
        },
        {
            dataIndex: "name",
            title: "Nazwa",
            width: "40%",
        },
        {
            dataIndex: "code",
            title: "Kod",
            width: "15%",
        },
        {
            dataIndex: "type",
            title: "Typ",
            width: "20%",
            render: (text) => (
              <QuestionnaireTypeName value={text}/>
            ),
        },
        {
            title: "Opcje",
            key: "otpions",
            width: "25%",
            render: (t, item) => (
                <RowOptions
                    questionnaireId={item.questionnaireId}
                />
            ),
        },
    ];

    const RowExpander = expandedRow;
    const expandedRowRender = RowExpander ? (item: Questionnaire) => (
        <RowExpander questionnaireId={item.questionnaireId}/>
    ) : undefined;

    const tableProps: TableProps<Questionnaire> = {
        dataSource: items,
        expandedRowRender,
        columns,
        rowKey: "questionnaireId",
        locale: {
            emptyText: (<Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="Brak danych"
            />),
        },
    };

    return onDragSorting
        ? <DragSortingTable onDragSorting={onDragSorting} {...tableProps} />
        : <Table {...tableProps} />;
};

export default QuestionnaireList;
