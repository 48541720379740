import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { clearSession } from "../../store/session/sessionActions";
import { StoreState } from "../../store/storeReducer";
import UserMenu, { UserMenuDispatchProps, UserMenuStateProps } from "./UserMenu";

const mapStateToProps: MapStateToProps<UserMenuStateProps, {}, StoreState> = ({currentUser}) => ({
    userId: currentUser ? currentUser.userId : "",
    name: currentUser ? currentUser.name : "",
    surname: currentUser ? currentUser.surname : "",
});

const mapDispatchToProps: MapDispatchToProps<UserMenuDispatchProps, {}> = (dispatch) => ({
    logout() {
        dispatch(clearSession());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
