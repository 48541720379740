import { Button } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { ComponentType } from "react";
import { QuestionnaireElement, QuestionnaireElementType } from "../../../resources/questionnaire/QuestionnaireElement";
import DragSortingTable from "../../dragSortingTable/DragSortingTable";
import QuestionnaireElementDeleteButton from "../questionnaireElementDeleteButton/QuestionnaireElementDeleteButton";
import QuestionEditButton from "../questionEditButton/QuestionEditButton";

export interface LikertQuestionnaireElementsStateProps {
    items: QuestionnaireElement[];
}

export interface LikertQuestionnaireElementsDispatchProps {
    remove(elementId: string): void;
    update(element: QuestionnaireElement): void;
    sort(dragIndex: number, hoverIndex: number): void;
}

type Props = LikertQuestionnaireElementsStateProps & LikertQuestionnaireElementsDispatchProps;

const LikertQuestionnaireElements: ComponentType<Props> = (props) => {
    const columns: Array<ColumnProps<QuestionnaireElement>> = [
        {
            key: "content",
            title: "Treść",
            render: (n, item) => item.type === QuestionnaireElementType.QUESTION ? item.description : '-',
        },
        {
            key: "options",
            width: "25%",
            title: "Opcje",
            render: (v, item) => (
                <Button.Group>
                    <QuestionEditButton questionnaireElement={item} onEdit={props.update}/>
                    <QuestionnaireElementDeleteButton onDelete={() => props.remove(item.elementId)}/>
                </Button.Group>
            ),
        },
    ];

    return (
        <DragSortingTable
            size="small"
            bordered={true}
            rowKey="elementId"
            dataSource={props.items}
            columns={columns}
            onDragSorting={props.sort}
        />
    );
};

export default LikertQuestionnaireElements;
