import { Avatar } from "antd";
import * as React from "react";
import { ComponentType } from "react";

export interface UserAvatarProps {
    name: string;
    surname: string;
}

const UserAvatar: ComponentType<UserAvatarProps> = ({name, surname}) => (
    <Avatar>
        {name.charAt(0)}
        {surname.charAt(0)}
    </Avatar>
);

export default UserAvatar;
