import axios from "axios";
import { push } from "connected-react-router";
import { call, put, takeEvery } from "redux-saga/effects";
import generateUuid from "../../../utils/generateUuid";
import { CreateSurveyButtonType } from "./CreateSurveyButtonType";
import {ErrorTypes} from "../../error/store/errorTypes";

export const currentSurveyIdStorageKey = "actual-survey-id";

export default function* () {
  yield takeEvery(CreateSurveyButtonType.ADD, createSurveySaga);
}

function* createSurveySaga() {
  try {
    const surveyId = yield call(generateUuid);
    yield call(axios.post,"/surveys", {id: surveyId});
    yield put(push(`/surveys/${surveyId}/edit/0`));
  } catch (error) {
    yield put({type: ErrorTypes.GENERAL, error});
    yield call(console.error, error);
  }
}
