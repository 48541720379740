import { Layout } from "antd";
import * as React from "react";
import { ComponentType } from "react";
import MainLayout from "../mainLayout/MainLayout";
import styles from "./blankLayout.module.scss";

const {Content} = Layout;

const BlankLayout: ComponentType = ({children}) => (
    <MainLayout>
        <Content className={styles.content}>
            {children}
        </Content>
    </MainLayout>
);

export default BlankLayout;
