import { Layout } from "antd";
import "antd/dist/antd.min.css";
import * as React from "react";
import { ComponentType } from "react";
import Helmet from "react-helmet";
import styles from "./mainLayout.module.scss";

const MainLayout: ComponentType = (props) => (
    <Layout className={styles.layout}>
        <Helmet>
            <title>Auxilio</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        </Helmet>
        {props.children}
    </Layout>
);

export default MainLayout;
