import {Button, Form, Icon, Input, Switch} from "antd";
import { FormComponentProps, FormCreateOption } from "antd/lib/form";
import { ColProps } from "antd/lib/grid";
import * as React from "react";
import { ComponentType, FormEvent } from "react";
import { emailRule, requiredRule } from "../../utils/validationRules";
import styles from "./userDetailsForm.module.scss";

export interface UserDetailsFormStateProps {
    name: string;
    surname: string;
    userId: string;
    email: string;
    active: boolean;
    loading: boolean;
    isAdmin: boolean;
}

export interface UserDetailsFormDispatchProps {
    onUpdate(model: UserDetailsFormStateProps): void;
}

type Props = UserDetailsFormStateProps & UserDetailsFormDispatchProps & FormComponentProps;

const UserDetailsForm: ComponentType<Props> = (props) => {
    const {getFieldDecorator} = props.form;
    const handleSubmit = (event: FormEvent): void => {
        event.preventDefault();
        props.form.validateFields((errors, values) => {
            if (!errors) {
                const model = {...values, userId: props.userId};
                props.onUpdate(model);
            }
        });
    };

    return (
        <Form
            wrapperCol={wrapperCol}
            labelCol={labelCol}
            className={styles.form}
            onSubmit={handleSubmit}
        >
            <Form.Item label="Imię">
                {getFieldDecorator("name", {rules: [requiredRule]})(
                    <Input
                        disabled={props.loading}
                        placeholder="Imię"
                    />,
                )}
            </Form.Item>
            <Form.Item label="Nazwisko">
                {getFieldDecorator("surname", {rules: [requiredRule]})(
                    <Input
                        disabled={props.loading}
                        placeholder="Nazwisko"
                    />,
                )}
            </Form.Item>
            <Form.Item label="E-mail">
                {getFieldDecorator("email", {rules: [requiredRule, emailRule]})(
                    <Input
                        disabled={props.loading}
                        placeholder="E-mail"
                    />,
                )}
            </Form.Item>
            {props.isAdmin ? (
            <Form.Item label="Aktywny">
                {getFieldDecorator("active", {
                    rules: [requiredRule],
                    valuePropName: 'checked',
                    initialValue: props.active,
                })(
                  <Switch
                    disabled={props.loading}
                    checkedChildren={<Icon type="check" />}
                    unCheckedChildren={<Icon type="close" />}
                  />,
                )}
            </Form.Item>
            ) : null}
            <Button
                htmlType="submit"
                icon={props.loading ? "loading" : "save"}
                type="primary"
                className={styles.formButton}
                loading={props.loading}
                disabled={props.loading}
            >
                Zapisz
            </Button>
        </Form>
    );
};

const labelCol: ColProps = {
    xs: {span: 24},
    sm: {span: 8},
};

const wrapperCol: ColProps = {
    xs: {span: 24},
    sm: {span: 16},
};

const formOptions: FormCreateOption<Props> = {
    mapPropsToFields(props) {
        let form = {
            name: Form.createFormField({
                value: props.name,
            }),
            surname: Form.createFormField({
                value: props.surname,
            }),
            email: Form.createFormField({
                value: props.email,
            }),
            active: undefined,
        };
        if (props.isAdmin) {
            form.active = Form.createFormField({
                value: props.active,
            });
        }
        return form;
    },
};

export default Form.create<Props>(formOptions)(UserDetailsForm);
