import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { StoreState } from "../../../store/storeReducer";
import {
    removeQuestionnaireFromSurveyForm,
    updateQuestionnaireInSurveyForm,
} from "../store/questionnaires/surveyFormQuestionnairesActions";
import QuestionnaireOptions, {
    QuestionnaireOptionsDispatchProps as DispatchProps,
    QuestionnaireOptionsStateProps as StateProps,
} from "./QuestionnaireOptions";

interface OwnProps {
    questionnaireId: string;
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, StoreState> = ({surveyForm}, ownProps) => {
    const questionnaire = surveyForm.questionnaires
        .find((item) => item.questionnaireId === ownProps.questionnaireId);

    if (null == questionnaire) {
        throw new Error(`Invalid questionnaire id ${ownProps.questionnaireId}`);
    }
    return {questionnaire};
};

const mapDispatchToProps: MapDispatchToProps<DispatchProps, OwnProps> = (dispatch, ownProps) => ({
    onDelete() {
        dispatch(removeQuestionnaireFromSurveyForm(ownProps.questionnaireId));
    },
    onEdit(questionnaire) {
        dispatch(updateQuestionnaireInSurveyForm(questionnaire));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireOptions);
