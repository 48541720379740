import * as React from "react";
import {Button} from "antd";
import {ComponentType} from "react";

export interface PublishSurveyButtonDispatchProps {
  onClick(): void;
}

export interface PublishSurveyButtonStateProps {
  surveyId: string,
  loading: boolean,
}

type Props = PublishSurveyButtonDispatchProps & PublishSurveyButtonStateProps;

const PublishSurveyButton: ComponentType<Props> = (props) => {
  return (
    <Button
      htmlType="button"
      icon="container"
      onClick={props.onClick}
      disabled={props.loading}
      loading={props.loading}
    >
      Publikuj
    </Button>
  );
};

export default PublishSurveyButton;
