import axios from "axios";
import { push } from "connected-react-router";
import { call, put, take, takeLatest } from "redux-saga/effects";
import { RouterType } from "../../../store/router/routerTypes";
import { logInFormError, logInFormReset, logInFormSubmit } from "./logInFormActions";
import { LogInFormTypes } from "./logInFormTypes";
import {ErrorTypes} from "../../error/store/errorTypes";

function* loginFormSaga() {
    yield takeLatest(LogInFormTypes.SUBMIT, handleSubmit);
}

function* handleSubmit({payload}: ReturnType<typeof logInFormSubmit>) {
    axios.defaults.headers["Authorization"] = `Basic ${btoa([payload.email, payload.password].join(":"))}`;

    try {
       yield call(axios.post,"sessions");
    } catch (e) {
        yield put(logInFormError(e));
        yield put({type: ErrorTypes.GENERAL, e});
        yield take(RouterType.LOCATION_CHANGE);
        yield put(logInFormReset());

        return;
    }

    yield put(logInFormReset());
    yield put(push(`/`));
}

export default loginFormSaga;
