import { Button, Col, Form, Input, InputNumber, Row } from "antd";
import { FormComponentProps } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import { ComponentType, default as React } from "react";
import { AnswerTypeValue } from "../../../resources/questionnaire/AnswerType";
import { maxRule, minRule, requiredRule } from "../../../utils/validationRules";
import TextAnswerValueTypeSelect from "../../textAnswerValueTypeSelect/TextAnswerValueTypeSelect";

const AnswerConfig: ComponentType<FormComponentProps> = (props) => {
    switch (props.form.getFieldValue("answerType") as AnswerTypeValue) {
        case AnswerTypeValue.TEXT:
            return (
                <Form.Item label="Oczekiwana wartość odpowiedzi">
                    {props.form.getFieldDecorator("valueType")(
                        <TextAnswerValueTypeSelect placeholder="Oczekiwany typ wartości odpowiedzi"/>,
                    )}
                </Form.Item>
            );
        case AnswerTypeValue.ANALOGUE:
            return (
                <Row>
                    <Col md={8}>
                        <Form.Item label="Krok">
                            {props.form.getFieldDecorator("step", {
                                initialValue: 1,
                                rules: [requiredRule],
                            })(
                                <InputNumber step={0.01}/>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Wartość minimalna">
                            {props.form.getFieldDecorator("minValue", {
                                initialValue: 1,
                                rules: [
                                    requiredRule,
                                    maxRule(props.form.getFieldValue("maxValue")),
                                ],
                            })(
                                <InputNumber step={props.form.getFieldValue("step")}/>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Wartość maksymalna">
                            {props.form.getFieldDecorator("maxValue", {
                                initialValue: 10,
                                rules: [
                                    requiredRule,
                                    minRule(props.form.getFieldValue("minValue")),
                                ],
                            })(
                                <InputNumber step={props.form.getFieldValue("step")}/>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item label="Opis minimalnej wartości">
                            {props.form.getFieldDecorator("minDescription", {
                                rules: [requiredRule],
                            })(
                                <TextArea placeholder="Opis minimalnej wartości"/>,
                            )}
                        </Form.Item>
                    </Col>
                    <Col xs={24}>
                        <Form.Item label="Opis maksymalnej wartości">
                            {props.form.getFieldDecorator("maxDescription", {
                                rules: [requiredRule],
                            })(
                                <TextArea placeholder="Opis maksymalnej wartości"/>,
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            );
        case AnswerTypeValue.SIMPLE:
            props.form.getFieldDecorator("namedAnswersKeys", {
                initialValue: [],
            });
            const namedAnswersKeys: number[] = [...props.form.getFieldValue("namedAnswersKeys")] || [];
            const addNamedAnswer = () => props.form.setFieldsValue(
                {
                    namedAnswersKeys: namedAnswersKeys.concat([namedAnswersKeys.length]),
                },
            );

            const removeItem = (index: number) => () => props.form.setFieldsValue(
                {
                    namedAnswersKeys: namedAnswersKeys.filter((i) => i !== index),
                },
            );

            return (
                <>
                    <Row>
                        <Col xs={10}>
                            <b>Opis</b>
                        </Col>
                        <Col xs={6}>
                            <b>Wartość wyświetlana</b>
                        </Col>
                        <Col xs={6}>
                            <b>Wartość</b>
                        </Col>
                        <Col xs={2} />
                    </Row>
                    {namedAnswersKeys.map((index) => (
                        <Row key={index}>
                            <Col xs={10}>
                                {props.form.getFieldDecorator(`namedAnswers[${index}].description`, {
                                    rules: [requiredRule],
                                    initialValue: "",
                                })(
                                    <Input placeholder="Opis wartości"/>,
                                )}
                            </Col>
                            <Col xs={6}>
                                {props.form.getFieldDecorator(`namedAnswers[${index}].displayedValue`, {
                                    rules: [requiredRule],
                                    initialValue: "0",
                                })(
                                    <Input placeholder="Wartość wyświetlana"/>,
                                )}
                            </Col>
                            <Col xs={6}>
                                {props.form.getFieldDecorator(`namedAnswers[${index}].value`, {
                                    rules: [requiredRule],
                                    initialValue: 0,
                                })(
                                    <InputNumber />,
                                )}
                            </Col>
                            <Col xs={2}>
                                <Button
                                    htmlType="button"
                                    type="danger"
                                    icon="delete"
                                    onClick={removeItem(index)}
                                />
                            </Col>
                        </Row>
                    ))}
                    <Button htmlType="button" onClick={addNamedAnswer} icon="add">Dodaj opcję odpowiedzi</Button>
                </>
            );
        default:
            return null;
    }
};

export default AnswerConfig;
