import {Checkbox, Form} from "antd";
import {FormComponentProps} from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import * as React from "react";
import {requiredRule} from "../../../utils/validationRules";
import {CheckboxChangeEvent} from "antd/es/checkbox";

interface State {
  private: boolean;
  required: boolean;
}

type Props = FormComponentProps;

class QuestionElementConfig extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      private: false,
      required: false,
    };
  };

  handleCheckboxRequired = (e: CheckboxChangeEvent) => {
    this.setState({required: e.target.checked});
    this.props.form.setFieldsValue({
      required: e.target.checked
    });
  };

  handleCheckboxPrivate = (e: CheckboxChangeEvent) => {
    this.setState({private: e.target.checked});
    this.props.form.setFieldsValue({
      private: e.target.checked
    });
  };

  public render() {
    return (
      <>
        <Form.Item label="Treść pytania">
          {this.props.form.getFieldDecorator("description", {rules: [requiredRule]})(
            <TextArea placeholder="Treść pytania"/>,
          )}
        </Form.Item>
        <Form.Item key="required">
          {this.props.form.getFieldDecorator("required", {
            valuePropName: "checked",
          })(
            <Checkbox value={this.state.required} onChange={this.handleCheckboxRequired}>Odpowiedź jest
              wymagana</Checkbox>,
          )}
        </Form.Item>
        <Form.Item>
          {this.props.form.getFieldDecorator("private", {
            valuePropName: "checked",
          })(
            <Checkbox value={this.state.private} onChange={this.handleCheckboxPrivate}>Dane wrażliwe</Checkbox>,
          )}
        </Form.Item>
      </>
    );
  };
}

export default QuestionElementConfig;
