import { push } from "connected-react-router";
import * as React from "react";
import { useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import { defaultPageSize } from "../../utils/pagination";
import PanelLayout from "../panelLayout/PanelLayout";
import { loadUserList, resetUserList } from "./store/userListActions";
import UserListContainer from "./UserListContainer";

const UserListPage = (props: RouteComponentProps) => {
    const search = new URLSearchParams(props.location.search);
    const page = parseInt(search.get("page") as string) || 1;
    const pageSize = parseInt(search.get("perPage") as string) || defaultPageSize;

    const dispatch = useDispatch();
    const onChange = useCallback((page: number, pageSize?: number) => {
        dispatch(push(`/users?page=${page}&pageSize=${pageSize}`));
    }, [dispatch]);
    const onClick = useCallback(() => {
        dispatch(push("/users/create"));
    }, [dispatch]);

    useEffect(() => {
        dispatch(loadUserList(page, pageSize));

        return () => {
            dispatch(resetUserList());
        };
    });

    return (
        <PanelLayout>
            <Helmet>
                <title>Lista użytkowników</title>
            </Helmet>
            <UserListContainer
                page={page}
                pageSize={pageSize}
                onClick={onClick}
                onChangePage={onChange}
            />
        </PanelLayout>
    );
};

export default UserListPage;
