import { connect, MapDispatchToProps } from "react-redux";
import CreateSurveyButton, { CreateSurveyButtonDispatchProps } from "./CreateSurveyButton";
import { createSurvey } from "./store/CreateSurveyButtonActions";

const mapDispatchToProps: MapDispatchToProps<CreateSurveyButtonDispatchProps, {}> = (dispatch) => ({
  onClick() {
    dispatch(createSurvey());
  },
});

export default connect(undefined, mapDispatchToProps)(CreateSurveyButton);
