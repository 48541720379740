import axios from "axios";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
    loadSurveyList,
    previewSurveyForm,
    publishedSurveyForm,
    publishSurveyForm,
    readySurveyList
} from "./surveyListActions";
import { SurveyListType } from "./SurveyListType";
import {ErrorTypes} from "../../error/store/errorTypes";

function* surveyListSaga() {
    yield takeEvery(SurveyListType.PUBLISH, publishSurveySaga);
    yield takeEvery(SurveyListType.PREVIEW, previewSurveySaga);
    yield takeLatest(SurveyListType.LOAD, loadSurveysSaga)
}

function* loadSurveysSaga({payload: {page, pageSize, status, anonymous}}: ReturnType<typeof loadSurveyList>) {
    const take = pageSize;
    const skip = (page - 1) * pageSize;
    try {
        const {data: {items, total}} = yield call(axios.get, "surveys", {params: {skip, take, status, anonymous}});
        yield put(readySurveyList(items, total));
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot load surveys.", e);
    }
}

function* publishSurveySaga({payload}: ReturnType<typeof publishSurveyForm>) {
    try {
        yield call(axios.put, `/surveys/${payload}/publish`);
        yield put(publishedSurveyForm(payload));
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot publish survey.", e);
    }
}

function* previewSurveySaga({payload}: ReturnType<typeof previewSurveyForm>) {
    try {
        const {data: {resourceUrl}} = yield call(axios.get, `/accesses/survey/preview?resourceId=${payload}`);
        yield call(window.open, resourceUrl, '_blank');
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot preview survey.", e);
    }
}

export default surveyListSaga;
