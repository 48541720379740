import { Steps } from "antd";
import * as React from "react";
import { ComponentType } from "react";
import surveyFormSteps from "./surveyFormSteps";

const {Step} = Steps;

export interface SurveyFormStepsStateProps {
    step: number;
}

export interface SurveyFormStepsDispatchProps {
    onChange(step: number): void;
}

type Props = SurveyFormStepsStateProps & SurveyFormStepsDispatchProps;

const SurveyFormSteps: ComponentType<Props> = ({step, onChange}) => (
    <Steps current={step}>
        {surveyFormSteps.map(({title}, index) => (
            <Step
                onClick={() => onChange(index)}
                key={index}
                status={step === index ? "process" : "wait"}
                title={title}
            />
        ))}
    </Steps>
);

export default SurveyFormSteps;
