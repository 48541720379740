import { Button, Modal } from "antd";
import { ComponentType, default as React } from "react";

interface QuestionnaireElementDeleteButtonDispatchProps {
    onDelete(): void;
}

type Props = QuestionnaireElementDeleteButtonDispatchProps;

const QuestionnaireElementDeleteButton: ComponentType<Props> = (props) => {
    const remove = () => Modal.confirm(
        {
            title: "Czy jesteś pewny, że chesz usunąć element?",
            icon: "delete",
            autoFocusButton: "cancel",
            okText: "Usuń",
            okType: "danger",
            okButtonProps: {icon: "delete"},
            onOk: props.onDelete,
        },
    );
    return (
        <Button
            htmlType="button"
            type="danger"
            icon="delete"
            onClick={remove}
        >
            Usuń
        </Button>
    );
};

export default QuestionnaireElementDeleteButton;
