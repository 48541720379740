import { Form, Modal } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { Component, CSSProperties } from "react";
import {
    AnalogueAnswerType,
    AnswerType,
    AnswerTypeValue,
    NamedAnswer,
    SimpleAnswerType,
    TextAnswerType,
} from "../../../resources/questionnaire/AnswerType";
import {
    Question,
    QuestionnaireElement,
    QuestionnaireElementType,
    TextElement,
} from "../../../resources/questionnaire/QuestionnaireElement";
import generateUuid from "../../../utils/generateUuid";
import { requiredRule } from "../../../utils/validationRules";
import AnswerTypeSelect from "../../answerTypeSelect/AnswerTypeSelect";
import QuestionnaireElementTypeSelect from "../../questionnaireElementTypeSelect/QuestionnaireElementTypeSelect";
import AnswerConfig from "./AnswerConfig";
import QuestionElementConfig from "./QuestionElementConfig";
import QuestionnaireElementModalSteps from "./QuestionnaireElementModalSteps";
import TextElementConfig from "./TextElementConfig";

interface QuestionnaireElementModalStateProps {
    isEdit: boolean;
    defaultValue?: QuestionnaireElement;
    visible: boolean;
    questionnaireId: string;
}

interface QuestionnaireElementModalDispatchProps {
    onCancel(): void;

    onSubmit(model: QuestionnaireElement): void;
}

type OwnProps = QuestionnaireElementModalStateProps & QuestionnaireElementModalDispatchProps;
type Props = FormComponentProps & OwnProps;

class QuestionnaireElementModal extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            step: 0,
        };
    }

    private get elementType(): QuestionnaireElementType | undefined {
        const {form} = this.props;

        return form.getFieldValue("type");
    }

    private get numberOfSteps() {
        return null == this.elementType || QuestionnaireElementType.TEXT === this.elementType ? 2 : 3;
    }

    public render() {
        const {visible, form} = this.props;
        const {step} = this.state;
        const isLastStep = step + 1 === this.numberOfSteps;

        return (
            <Modal
                width={800}
                align={undefined}
                visible={visible}
                okText={isLastStep ? "Dodaj" : "Dalej"}
                cancelText={step ? "Wróć" : "Anuluj"}
                onCancel={step ? this.prevStep : this.cancel}
                onOk={isLastStep ? this.submitForm : this.nextStep}
                title={this.props.isEdit ? "Edytowanie elementu kwestionariusza" : "Dodawanie elementu do kwestionariusza"}
            >
                <QuestionnaireElementModalSteps
                    current={step}
                    steps={this.numberOfSteps}
                    onChange={this.changeStep}
                />
                <Form>
                    <Form.Item
                        label="Typ elementu"
                        style={this.getStepStyle(0)}
                    >
                        {form.getFieldDecorator("type", {
                            rules: [requiredRule],
                        })(
                            <QuestionnaireElementTypeSelect disabled={this.props.isEdit} placeholder="Typ elementu"/>,
                        )}
                    </Form.Item>
                    <div style={this.getStepStyle(1)}>
                        {this.renderStep1Content()}
                    </div>
                    <div style={this.getStepStyle(2)}>
                        {this.renderStep2Content()}
                    </div>
                </Form>
            </Modal>
        );
    }

    private getStepStyle = (step: number): CSSProperties => ({
        display: step !== this.state.step ? "none" : "block",
    })

    private cancel = () => {
        this.props.onCancel();
        this.props.form.resetFields();
    }

    private submitForm = () => this.props.form.validateFields((err, values) => {
        if (err) {
            return;
        }

        const element = this.getElementFromValues(values);
        this.setState({step: 0});
        this.props.form.resetFields();
        this.props.onSubmit(element);
    })

    private getElementFromValues = (values: any): QuestionnaireElement => {
        const {questionnaireId} = this.props;
        const elementId = this.props.isEdit && !!this.props.defaultValue ? this.props.defaultValue.elementId : generateUuid();

        switch (values.type as QuestionnaireElementType) {
            case QuestionnaireElementType.TEXT: {
                const element: TextElement = {
                    type: QuestionnaireElementType.TEXT,
                    elementId,
                    text: values.text,
                    questionnaireId,
                };

                return element as QuestionnaireElement;
            }
            case QuestionnaireElementType.QUESTION: {
                const element: Question = {
                    type: QuestionnaireElementType.QUESTION,
                    elementId,
                    questionnaireId,
                    required: values.required,
                    private: values.private,
                    description: values.description,
                    answerType: this.getAnswerTypeFromValues(values),
                };

                return element as QuestionnaireElement;
            }
            default:
                throw new Error(`Unknown questionnaire element type ${values.type}`);
        }
    }

    private getAnswerTypeFromValues = (values: any): null|AnswerType => {
        if (!values.answerType) {
            return null;
        }
        const answerTypeId = this.props.isEdit
          ? values.answerType.answerTypeId
          : generateUuid();

        switch (values.answerType as AnswerTypeValue) {
                case AnswerTypeValue.TEXT: {
                    const answerType: TextAnswerType = {
                        type: AnswerTypeValue.TEXT,
                        answerTypeId,
                        valueType: values.valueType,
                    };

                    return answerType as AnswerType;
                }
                case AnswerTypeValue.ANALOGUE: {
                    const answerType: AnalogueAnswerType = {
                        type: AnswerTypeValue.ANALOGUE,
                        answerTypeId,
                        leftDescription: values.minDescription,
                        rightDescription: values.maxDescription,
                        step: values.step,
                        maxValue: values.maxValue,
                        minValue: values.minValue,
                    };

                    return answerType as AnswerType;
                }
                case AnswerTypeValue.SIMPLE: {
                    const answerType: SimpleAnswerType = {
                        minCount: 1,
                        maxCount: 1,
                        answerTypeId,
                        type: AnswerTypeValue.SIMPLE,
                        namedAnswers: values.namedAnswers,
                    };

                    return answerType as AnswerType;
                }
                default:
                    throw new Error(`Unsupported answer type ${values.answerType}`);
        }
    }

    private nextStep = () => {
        this.props.form.validateFields((err) => {
            if (!err) {
                this.setState(({step}) => ({
                    step: Math.min(this.numberOfSteps, step + 1),
                }));
            }
        });
    }

    private prevStep = () => this.setState(({step}) => ({
        step: Math.max(0, step - 1),
    }))

    private changeStep = (step: number) => {
        if (step < this.state.step) {
            this.setState({step});
        }
    }

    private renderStep1Content = () => {
        const {form} = this.props;
        const {step} = this.state;

        if (step < 1) {
            return null;
        }

        switch (this.elementType) {
            case QuestionnaireElementType.TEXT: {
                return <TextElementConfig form={form}/>;
            }
            case QuestionnaireElementType.QUESTION: {
                return <QuestionElementConfig form={form}/>;
            }
            default:
                return null;
        }
    }

    private renderStep2Content = () => {
        const {form} = this.props;
        const {step} = this.state;

        if (step < 2) {
            return null;
        }

        if (this.elementType !== QuestionnaireElementType.QUESTION) {
            return null;
        }

        return (
            <>
                <Form.Item label="Typ odpowiedzi">
                    {form.getFieldDecorator("answerType", {rules: [requiredRule]})(
                        <AnswerTypeSelect placeholder="Typ odpowiedzi"/>,
                    )}
                </Form.Item>
                <AnswerConfig form={form}/>
            </>
        );
    }
}

interface State {
    step: number;
}

export default Form.create<Props>({
    onValuesChange: (props, changedValues, allValues) => {
        console.log(props);
        console.log(changedValues);
        console.log(allValues);
    },
    mapPropsToFields: (props) => {
        if (!!props.defaultValue) {
            if (QuestionnaireElementType.QUESTION === props.defaultValue.type) {

                const getAnswer = (answerType: null|AnswerType) => {
                    if (null === answerType) {
                        return {
                            answerType: Form.createFormField({value: null}),
                        };
                    }
                    if (AnswerTypeValue.TEXT === answerType.type) {
                        return {
                            answerTypeId: Form.createFormField({value: answerType.answerTypeId}),
                            answerType: Form.createFormField({value: answerType.type}),
                            valueType: Form.createFormField({value: answerType.valueType}),
                        };
                    }
                    if (AnswerTypeValue.ANALOGUE === answerType.type) {
                        return {
                            answerTypeId: Form.createFormField({value: answerType.answerTypeId}),
                            answerType: Form.createFormField({value: answerType.type}),
                            step: Form.createFormField({value: answerType.step}),
                            minValue: Form.createFormField({value: answerType.minValue}),
                            maxValue: Form.createFormField({value: answerType.maxValue}),
                            minDescription: Form.createFormField({value: answerType.leftDescription}),
                            maxDescription: Form.createFormField({value: answerType.rightDescription}),
                        };
                    }
                    if (AnswerTypeValue.SIMPLE === answerType.type) {
                        const getAnswers = (namedAnswers: NamedAnswer[]) => {
                            let answers:any = [];
                            if (!!namedAnswers) {
                                for (let index of namedAnswers.keys()) {
                                    answers[index] = {
                                        description: Form.createFormField({value: namedAnswers[index].description}),
                                        displayedValue: Form.createFormField({value: namedAnswers[index].displayedValue}),
                                        value: Form.createFormField({value: namedAnswers[index].value}),
                                    };
                                }
                            }

                            return answers;
                        };
                        return {
                            answerTypeId: Form.createFormField({value: answerType.answerTypeId}),
                            answerType: Form.createFormField({value: answerType.type}),
                            namedAnswersKeys: Form.createFormField({
                                value: !!answerType.namedAnswers ? answerType.namedAnswers.keys() : []
                            }),
                            namedAnswers: [...getAnswers(answerType.namedAnswers)],
                        };
                    }

                    throw new Error("Invalid questionnaire answer type.");
                };
                return {
                    questionnaireId: Form.createFormField({value: props.defaultValue.questionnaireId}),
                    elementId: Form.createFormField({value: props.defaultValue.elementId}),
                    type: Form.createFormField({value: props.defaultValue.type}),
                    private: Form.createFormField({value: props.defaultValue.private}),
                    required: Form.createFormField({value: props.defaultValue.required}),
                    description: Form.createFormField({value: props.defaultValue.description}),
                    ...(getAnswer(props.defaultValue.answerType)),
                }
            }

            if (QuestionnaireElementType.TEXT === props.defaultValue.type) {
                return {
                    questionnaireId: Form.createFormField({value: props.defaultValue.questionnaireId}),
                    elementId: Form.createFormField({value: props.defaultValue.elementId}),
                    type: Form.createFormField({value: props.defaultValue.type}),
                    text: Form.createFormField({value: props.defaultValue.text}),
                }
            }

            throw new Error("Invalid questionnaire element type.");
        } else {
            return {};
        }
    },
})(QuestionnaireElementModal);
