import * as React from "react";
import { ComponentType } from "react";

interface PercentProps {
    value: number;
    precision?: number;
}

const Percent: ComponentType<PercentProps> = ({value, precision}) => {
    const e = Math.pow(10, precision || 0);
    const n = Math.round(value * e * 100) / e;
    const v = n.toString().replace('.', ',');

    return <>{v}%</>;
};

export default Percent;
