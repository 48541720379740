import {connect, MapDispatchToProps, MapStateToProps} from "react-redux";
import PublishSurveyButton, {
  PublishSurveyButtonDispatchProps,
  PublishSurveyButtonStateProps
} from "./PublishSurveyButton";
import {publishSurveyForm} from "../surveyList/store/surveyListActions";
import {StoreState} from "../../store/storeReducer";

interface OwnProps {
  surveyId: string,
}

const mapStateToProps: MapStateToProps<PublishSurveyButtonStateProps, OwnProps, StoreState> = ({surveyList}, {surveyId}) => ({
  surveyId,
  loading: surveyList.loadingItemIds.findIndex((uuid) => uuid === surveyId) > -1,
});

const mapDispatchToProps: MapDispatchToProps<PublishSurveyButtonDispatchProps, OwnProps> = (dispatch, {surveyId}) => ({
  onClick() {
    dispatch(publishSurveyForm(surveyId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PublishSurveyButton);
