import { Reducer } from "redux";
import { UserEntity } from "../../../resources/UserEntity";
import { readyUserList } from "./userListActions";
import { UserListTypes } from "./userListTypes";

export interface UserListState {
    loading: boolean;
    total: number;
    items: UserEntity[];
}

const userListInitialState: UserListState = {
    loading: false,
    total: 0,
    items: [],
};

export const userListReducer: Reducer<UserListState> = (state = userListInitialState, action) => {
    switch (action.type as UserListTypes | any) {
        case UserListTypes.RESET:
            return userListInitialState;
        case UserListTypes.LOAD:
            return {
                ...state,
                loading: true,
            };
        case UserListTypes.READY: {
            const {payload: {items, total}} = action as ReturnType<typeof readyUserList>;
            return {
                ...state,
                loading: false,
                items,
                total,
            };
        }
    }
    return state;
};
