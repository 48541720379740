import { Button, Checkbox, Form, Icon, Input } from "antd";
import { FormComponentProps } from "antd/lib/form";
import * as React from "react";
import { ComponentType, FormEventHandler } from "react";
import { emailRule, requiredRule } from "../../utils/validationRules";
import ErrorMessage from "../error/ErrorMessage";
import styles from "./logInForm.module.scss";

export interface LogInFormStateProps {
    loading: boolean;
    error?: any;
}

export interface LoginFormDispatchProps {
    logIn(email: string, password: string, rememberMe: boolean): void;
}

type LoginFormProps = LogInFormStateProps & LoginFormDispatchProps;

const LogInForm: ComponentType<LoginFormProps & FormComponentProps> = (props) => {
    const {getFieldDecorator} = props.form;
    const onSubmit: FormEventHandler = (event) => {
        event.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                props.logIn(values.email, values.password, values.remember);
            }
        });
    };
    return (
        <Form className={styles.form} onSubmit={onSubmit}>
            <h1>Logowanie</h1>
            {props.error ? <ErrorMessage error={props.error} closable={true}/> : null}
            <Form.Item>
                {getFieldDecorator("email", {rules: [requiredRule, emailRule]})(
                    <Input
                        autoComplete="username"
                        prefix={<Icon type="user" className={styles.icon}/>}
                        type="email"
                        placeholder="Nazwa użytkownika"
                        readOnly={props.loading}
                    />,
                )}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator("password", {rules: [requiredRule]})(
                    <Input
                        autoComplete="current-password"
                        prefix={<Icon type="lock" className={styles.icon}/>}
                        type="password"
                        placeholder="Hasło"
                        readOnly={props.loading}
                    />,
                )}
            </Form.Item>
            <Form.Item>
                {getFieldDecorator("remember", {initialValue: false})(
                    <Checkbox>Zapamiętaj mnie</Checkbox>,
                )}
                <Button
                    type="primary"
                    htmlType="submit"
                    className={styles.submit}
                    loading={props.loading}
                >
                    Zaloguj się
                </Button>
            </Form.Item>
        </Form>
    );
};

export default Form.create()(LogInForm);
