import * as React from "react";
import {Button} from "antd";
import {QuestionnaireElement} from "../../../resources/questionnaire/QuestionnaireElement";
import LikertQuestionnaireElementModal from "../likertQuestionnaireElementModal/LikertQuestionnaireElementModal";

export interface QuestionStateProps {
  questionnaireElement: QuestionnaireElement;
}

interface QuestionEditButtonDispatchProps {
  onEdit(questionnaireElement: QuestionnaireElement): void;
}

type Props = QuestionStateProps & QuestionEditButtonDispatchProps;

interface State {
  modal: boolean;
}

class QuestionEditButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {modal: false};
  }

  public render() {
    return (
      <>
        <LikertQuestionnaireElementModal
          isEdit={true}
          defaultValue={this.props.questionnaireElement}
          questionnaireId={this.props.questionnaireElement.questionnaireId}
          onSubmit={this.onEditSubmit}
          onCancel={this.closeEditModal}
          visible={this.state.modal}
        />
        <Button
          htmlType="button"
          type="default"
          icon="edit"
          onClick={this.showEditModal}
        >
          Edytuj
        </Button>
      </>
    );
  };

  private onEditSubmit = (model: QuestionnaireElement) => {
    this.setState({modal: false});
    this.props.onEdit(model);
  };

  private closeEditModal = () => this.setState({modal: false});

  private showEditModal = () => this.setState({modal: true});
}

export default QuestionEditButton;
