import axios from "axios";
import { AnyAction } from "redux";
import { call, takeLatest, put, takeEvery } from "redux-saga/effects";
import { loadCurrentSurveyForm } from "../surveyFormActions";
import { SurveyFormType } from "../surveyFormTypes";
import { setSurveyFormDetails, updateSurveyFormDetails } from "./surveyFormDetailsActions";
import { SurveyFormDetailsType } from "./surveyFormDetailsTypes";
import {ErrorTypes} from "../../../error/store/errorTypes";

function* surveyFormDetailsSaga() {
  yield takeLatest(SurveyFormDetailsType.UPDATE, sendSurveyUpdateSaga);
  yield takeEvery(
      (event: AnyAction) => event.type === SurveyFormType.LOAD && event.payload.step === 0,
      loadCurrentSurveySaga,
  );
}

function* sendSurveyUpdateSaga({payload: {surveyId, details}}: ReturnType<typeof updateSurveyFormDetails>) {
  try {
    yield call(axios.put,`/surveys/${surveyId}`, details);
  } catch (e) {
    yield put({type: ErrorTypes.GENERAL, e});
    yield call(console.warn, "Cannot send survey details", e);
  }
}

function* loadCurrentSurveySaga({payload: {surveyId}}: ReturnType<typeof loadCurrentSurveyForm>) {
  try {
    const response = yield call(axios.get,`/surveys/${surveyId}/details`);
    yield put(setSurveyFormDetails(response.data));
  } catch (e) {
    yield put({type: ErrorTypes.GENERAL, e});
    yield call(console.warn, "Cannot load survey details.", e);
  }
}

export default surveyFormDetailsSaga;
