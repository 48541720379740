import { Layout } from "antd";
import * as React from "react";
import { ComponentType } from "react";
import configuration from "../../resources/configuration";
import MainLayout from "../mainLayout/MainLayout";
import SideMenuContainer from "../sideMenu/SideMenuContainer";
import UserMenuContainer from "../userMenu/UserMenuContainer";
import styles from "./panelLayout.module.scss";

const {Header, Content, Footer} = Layout;

interface PanelLayoutProps {
    withoutBackground?: boolean;
}

const PanelLayout: ComponentType<PanelLayoutProps> = ({children, withoutBackground}) => (
    <MainLayout>
        <SideMenuContainer />
        <Layout>
            <Header className={styles.header}>
                <UserMenuContainer/>
            </Header>
            <Content className={styles.main}>
                <div className={withoutBackground ? styles.content : styles.contentWithBackground}>
                    {children}
                </div>
            </Content>
            <Footer className={styles.footer}>
                ©{new Date().getFullYear()} Wersja aplikacji: { configuration.version }
            </Footer>
        </Layout>
    </MainLayout>
);

export default PanelLayout;
