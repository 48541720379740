import { Reducer } from "redux";
import { Questionnaire } from "../../../../resources/questionnaire/Questionnaire";
import { SurveyFormType } from "../surveyFormTypes";
import {
    addQuestionnaireToSurveyForm,
    removeQuestionnaireFromSurveyForm,
    setSurveyFormQuestionnaires,
    sortQuestionnaireScaleInSurveyForm,
    sortQuestionnairesInSurveyForm,
    updateQuestionnaireInSurveyForm,
    updateQuestionnaireScaleInSurveyForm,
} from "./surveyFormQuestionnairesActions";
import { SurveyFormQuestionnairesType } from "./surveyFormQuestionnairesTypes";

export type SurveyFormQuestionnairesState = Questionnaire[];

export const surveyFormQuestionnairesReducer: Reducer<SurveyFormQuestionnairesState> = (state = [], action) => {
    if (action.type as SurveyFormType === SurveyFormType.RESET) {
        return [];
    }

    switch (action.type as SurveyFormQuestionnairesType) {
        case SurveyFormQuestionnairesType.ADD: {
            const {payload} = action as ReturnType<typeof addQuestionnaireToSurveyForm>;

            return state.concat([payload.questionnaire]);
        }
        case SurveyFormQuestionnairesType.REMOVE: {
            const {payload} = action as ReturnType<typeof removeQuestionnaireFromSurveyForm>;

            return state.filter(({questionnaireId}) => questionnaireId !== payload);
        }
        case SurveyFormQuestionnairesType.UPDATE: {
            const {payload} = action as ReturnType<typeof updateQuestionnaireInSurveyForm>;

            return state.map((item) => item.questionnaireId === payload.questionnaireId ? ({
                ...item,
                ...payload,
            }) : item);
        }
        case SurveyFormQuestionnairesType.UPDATE_SCALE: {
            const {payload} = action as ReturnType<typeof updateQuestionnaireScaleInSurveyForm>;

            return state.map((item) => item.questionnaireId === payload.questionnaireId ? ({
                ...item,
                scale: {elements: [...payload.elements]},
            }) : item);
        }
        case SurveyFormQuestionnairesType.SORT_SCALE: {
            const {payload: {dragIndex, hoverIndex, questionnaireId}} = action as ReturnType<typeof sortQuestionnaireScaleInSurveyForm>;

            return state.map((item) => {
                if (item.questionnaireId !== questionnaireId) {
                    return item;
                }
                const elements = item.scale ? item.scale.elements : [];
                const newStateElements = [...elements];
                newStateElements.splice(dragIndex, 1);
                newStateElements.splice(hoverIndex, 0, elements[dragIndex]);

                return {
                    ...item,
                    scale: {elements: newStateElements},
                };
            });
        }
        case SurveyFormQuestionnairesType.SORT: {
            const {payload: {drag}} = action as ReturnType<typeof sortQuestionnairesInSurveyForm>;

            const newState = [...state];
            newState.splice(drag.dragIndex, 1);
            newState.splice(drag.hoverIndex, 0, state[drag.dragIndex]);

            return newState;
        }
        case SurveyFormQuestionnairesType.SET: {
            const {payload} = action as ReturnType<typeof setSurveyFormQuestionnaires>;

            return payload;
        }
    }
    return state;
};
