import * as React from "react";
import { ComponentType } from "react";
import { AnswerTypeValue, TextAnswerValueType } from "../../resources/questionnaire/AnswerType";
import AnswerValueTypeName from "../answerValueTypeName/AnswerValueTypeName";

interface Props {
    type: AnswerTypeValue;
    valueType?: TextAnswerValueType;
}

const AnswerTypeName: ComponentType<Props> = (props) => {
    switch (props.type) {
        case AnswerTypeValue.TEXT:
            return (
                <>
                    Tekst
                    {
                        props.valueType
                            ? <> (<AnswerValueTypeName type={props.valueType}/>)</>
                            : null
                    }
                </>
            );
        case AnswerTypeValue.ANALOGUE:
            return <>Skala anologowa</>;
        case AnswerTypeValue.SIMPLE:
            return <>List wyboru</>;
        default:
            console.error(`Unknown answer type name '${props.type}'`);
            return null;
    }
};

export default AnswerTypeName;
