import * as React from "react";
import { ComponentType } from "react";
import { QuestionnaireElementType } from "../../resources/questionnaire/QuestionnaireElement";

interface Props {
    type: QuestionnaireElementType;
}

const QuestionnaireElementTypeName: ComponentType<Props> = ({type}) => {
    switch (type) {
        case QuestionnaireElementType.TEXT:
            return <>Blok tekstu</>;
        case QuestionnaireElementType.QUESTION:
            return <>Pytanie</>;
        default:
            console.error(`Unexpected questionnaire element type '${type}'`);
            return null;
    }
};

export default QuestionnaireElementTypeName;
