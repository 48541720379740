import { Button, Icon, Popconfirm, Tooltip } from "antd";
import { ComponentType, default as React, useCallback } from "react";
import { Link } from "react-router-dom";
import styles from "./surveyFormFlowControls.module.scss";

export interface SurveyFormFlowControlsStateProps {
    step: number;
    canPublish: boolean;
}

export interface SurveyFormFlowControlsDispatchProps {
    change(step: number): void;

    publish(): void;
}

type Props = SurveyFormFlowControlsStateProps & SurveyFormFlowControlsDispatchProps;

const SurveyFormFlowControls: ComponentType<Props> = ({step, canPublish, change, publish}) => {
    const prev = useCallback(() => change(step - 1), [change, step]);
    const next = useCallback(() => change(step + 1), [change, step]);

    const backToList = (
        <Link to="/surveys">
            <Button htmlType="button">
                Powrót do listy badań
            </Button>
        </Link>
    );

    const prevButton = (
        <Button
            htmlType="button"
            type="default"
            onClick={prev}
        >
            <Icon type="arrow-left"/>
            &nbsp;Wstecz
        </Button>
    );

    const nextButton = (
        <Button
            htmlType="button"
            type="primary"
            onClick={next}
        >
            Dalej <Icon type="arrow-right"/>
        </Button>
    );

    const finishButton = (
        <Button
            disabled={!canPublish}
            htmlType="button"
            type="primary"
            icon="container"
        >
            Publikuj
        </Button>
    );

    const finishButtonWithConfirmation = canPublish ? (
        <Popconfirm
            title="Czy na pewno chcesz opublikować badanie?"
            placement="left"
            cancelText="Anuluj"
            okText="Zakończ"
            onConfirm={publish}
        >
            {finishButton}
        </Popconfirm>
    ) : (
        <Tooltip
            title="Popraw błędy w szkicu badania aby umożliwić jego publikację"
            placement="left"
        >
            {finishButton}
        </Tooltip>
    );

    return (
        <>
            <>
                {step === 0 ? backToList : null}
                {step > 0 ? prevButton : null}
            </>
            <div className={styles.nextButton}>
                {step <= 1 ? nextButton : null}
                {step === 2 ? backToList : null}
                {step === 2 ? finishButtonWithConfirmation : null}
            </div>
        </>
    );
};

export default SurveyFormFlowControls;
