import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { StoreState } from "../../../store/storeReducer";
import { updateSurveyFormDetails } from "../store/details/surveyFormDetailsActions";
import SurveyFormDetails, { SurveyFormDetailsDispatchProps, SurveyFormDetailsStateProps } from "./SurveyFormDetails";
import { debounce } from "lodash";

interface OwnProps {
    surveyId: string;
}

const mapDispatchToProps: MapDispatchToProps<SurveyFormDetailsDispatchProps, OwnProps> = (dispatch, props) => ({
    onChange: debounce((model) => {
        dispatch(updateSurveyFormDetails(props.surveyId, model));
    }, 1000),
});

const mapStateToProps: MapStateToProps<SurveyFormDetailsStateProps, OwnProps, StoreState> = ({surveyForm}) => {
    return surveyForm.details;
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyFormDetails);
