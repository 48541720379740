import { SurveyFormDetailsModel } from "../../surveyFormDetails/SurveyFormDetails";
import { SurveyFormDetailsType } from "./surveyFormDetailsTypes";

export const updateSurveyFormDetails = (surveyId: string, details: SurveyFormDetailsModel) => ({
    type: SurveyFormDetailsType.UPDATE,
    payload: {surveyId, details},
});

export type UpdateSurveyFormDetailsAction = ReturnType<typeof updateSurveyFormDetails>;

export const setSurveyFormDetails = (details: SurveyFormDetailsModel) => ({
    type: SurveyFormDetailsType.SET,
    payload: details,
});

export type SetSurveyFormDetails = ReturnType<typeof setSurveyFormDetails>;
