import { call, takeEvery, select } from 'redux-saga/effects'
import {ErrorTypes} from "./errorTypes";
import * as Sentry from "@sentry/browser";
import {getCurrentUserId} from "../../../store/currentUser/currentUserSelectors";

function* bubbleErrors(error: any) {
  if (process.env.NODE_ENV === 'development') {
    yield call(console.error, error);
  }
  const currentUserId = yield select(getCurrentUserId);

  yield call(Sentry.setUser, {id: currentUserId});
  yield call(Sentry.captureException, error);
}

export default function* () {
  yield takeEvery(ErrorTypes.GENERAL, bubbleErrors)
}
