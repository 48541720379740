import { Button, Icon, Popconfirm } from "antd";
import * as React from "react";
import { ComponentType } from "react";

export interface CreateSurveyButtonDispatchProps {
  onClick(): void;
}

type Props = CreateSurveyButtonDispatchProps;

const CreateSurveyButton: ComponentType<Props> = ({onClick}) => (
    <Popconfirm
        title="Czy na pewno chcesz utworzyć nowe badanie?"
        icon={<Icon type="question-circle" />}
        placement="left"
        okText="Tak"
        cancelText="Nie"
        onConfirm={onClick}
    >
        <Button
            htmlType="button"
            type="primary"
            icon="plus"
        >
            Utwórz nowe badanie
        </Button>
    </Popconfirm>
);

export default CreateSurveyButton;
