import { Avatar, List } from "antd";
import * as React from "react";
import { ComponentType } from "react";

export interface SurveyFormSummaryStateProps {
    validName: boolean;
    validCode: boolean;
    validDescription: boolean;
}

type Props = SurveyFormSummaryStateProps;

const SurveyFormSummary: ComponentType<Props> = (props) => {
    const checkList: CheckListItem[] = [
        {
            title: "Nazwa badania",
            description: "Badanie powinno posiadać nazwę",
            status: props.validName,
        },
        {
            title: "Kod badania",
            description: "Badanie powinno posiadać kod",
            status: props.validCode,
        },
        {
            title: "Opis badania",
            description: "Badanie powinno posiadać opis",
            status: props.validCode,
        },
    ];

    return (
                <List
                    renderItem={(item: CheckListItem) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={<Avatar
                                    icon={item.status ? "check-circle" : "warning"}
                                    style={{color: item.status ? "green" : "red"}}
                                />}
                                title={item.title}
                                description={item.description}
                            />
                        </List.Item>
                    )}
                    dataSource={checkList}
                    itemLayout="horizontal"
                />
    );
};

export default SurveyFormSummary;

interface CheckListItem {
    title: string;
    description: string;
    status: boolean;
}
