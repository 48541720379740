import { Reducer } from "redux";
import { setCurrentUser, updateCurrentUser } from "./currentUserActions";
import { CurrentUserTypes } from "./currentUserTypes";

export type CurrentUserState = null | {
    userId: string;
    name: string;
    surname: string;
    email: string;
    active: boolean;
    isAdmin: boolean;
};

export const currentUserReducer: Reducer<CurrentUserState> = (state = null, action): CurrentUserState => {
    if (action.type as CurrentUserTypes === CurrentUserTypes.SET) {
        const {payload} = action as ReturnType<typeof setCurrentUser>;
        if (!payload) {
            return null;
        }
        return {
            userId: payload.userId,
            surname: payload.surname,
            name: payload.name,
            email: payload.email,
            active: payload.active,
            isAdmin: payload.isAdmin,
        };
    }
    if (action.type as CurrentUserTypes === CurrentUserTypes.UPDATE) {
        const {payload} = action as ReturnType<typeof updateCurrentUser>;
        if (!payload) {
            return null;
        }
        if (!state) {
            return {
                userId: '',
                surname: '',
                name: '',
                email: '',
                active: false,
                isAdmin: false,
                ...payload,
            }
        }
        return {
            ...state,
            ...payload,
        };
    }

    return state;
};
