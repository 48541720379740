import { SurveyListItem } from "../../../resources/survey/List/SurveyListItem";
import {AnonymousTag, SurveyStatus} from "../../../resources/SurveyEntity";
import { defaultPageSize } from "../../../utils/pagination";
import { SurveyListType } from "./SurveyListType";

export const loadSurveyList = (
    {
      page = 1,
      pageSize = defaultPageSize,
      status,
      anonymous,
    }: {
      page?: number,
      pageSize?: number,
      status?: SurveyStatus,
      anonymous?: AnonymousTag[],
    }
) => ({
  type: SurveyListType.LOAD,
  payload: {page, pageSize, status, anonymous},
});

export const readySurveyList = (items: SurveyListItem[], total: number) => ({
  type: SurveyListType.READY,
  payload: {items, total},
});

export const resetSurveyList = () => ({
  type :SurveyListType.RESET,
});

export const publishSurveyForm = (surveyId: string) => ({
  type: SurveyListType.PUBLISH,
  payload: surveyId,
});

export const previewSurveyForm = (surveyId: string) => ({
  type: SurveyListType.PREVIEW,
  payload: surveyId,
});

export const publishedSurveyForm = (surveyId: string) => ({
  type: SurveyListType.PUBLISHED,
  payload: surveyId,
});
