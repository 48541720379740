import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { setSideMenuState } from "../../store/sideMenu/sideMenuActions";
import { StoreState } from "../../store/storeReducer";
import SideMenu, { SideMenuDispatchProps, SideMenuStateProps } from "./SideMenu";
import {isAdmin} from "../../store/currentUser/currentUserSelectors";

const mapStateToProps: MapStateToProps<SideMenuStateProps, {}, StoreState> = (state) => ({
    currentPath: state.router.location.pathname,
    collapsed: state.sideMenu,
    isAdmin: isAdmin(state),
});

const mapDispatchToProps: MapDispatchToProps<SideMenuDispatchProps, {}> = (dispatch) => ({
    onCollapse(collapsed: boolean) {
        dispatch(setSideMenuState(collapsed));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
