import { Reducer } from "redux";
import { SideMenuTypes } from "./sideMenuTypes";

export type SideMenuState = boolean;

export const sideMenuReducer: Reducer<SideMenuState> = (state = false, action) => {
    if (action.type === SideMenuTypes.SET) {
        return true === action.payload;
    }

    return state;
};
