import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { StoreState } from "../../../store/storeReducer";
import {getQuestionnaireElements} from "../store/elements/surveyFormElementsSelectors";
import LikertQuestionnaireForm, {
  LikertQuestionnaireFormDispatchProps,
  LikertQuestionnaireFormStateProps
} from "./LikertQuestionnaireForm";
import {
  addQuestionnaireElementToSurveyForm, updateQuestionnaireElementInSurveyForm,
} from "../store/elements/surveyFormElementsActions";

interface OwnProps {
  questionnaireId: string;
}

const mapStateToProps: MapStateToProps<LikertQuestionnaireFormStateProps, OwnProps, StoreState> = (state, ownProps) => ({
    elements: getQuestionnaireElements(ownProps.questionnaireId)(state),
    questionnaireId: ownProps.questionnaireId,
});

const mapDispatchToProps: MapDispatchToProps<LikertQuestionnaireFormDispatchProps, OwnProps> = (dispatch) => ({
  addElement(element) {
    dispatch(addQuestionnaireElementToSurveyForm(element));
  },
  updateElement(element) {
    dispatch(updateQuestionnaireElementInSurveyForm(element));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LikertQuestionnaireForm);
