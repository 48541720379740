import axios from "axios";
import { push } from "connected-react-router";
import { call, put } from "redux-saga/effects";
import { PublishSurveyFormAction } from "./surveyFormPublishActions";
import {ErrorTypes} from "../../../error/store/errorTypes";

function* surveyFormPublishSaga(action: PublishSurveyFormAction) {
    const surveyId = action.payload;
    try {
        yield call(axios.put, `surveys/${surveyId}/publish`);
        yield put(push("/surveys"));
    } catch (e) {
        yield put({type: ErrorTypes.GENERAL, e});
        yield call(console.warn,"Cannot publish survey.", e);
    }
}

export default surveyFormPublishSaga;
