import { ApiAuthorization } from "../../resources/ApiAuthorization";
import { SessionType } from "./sessionTypes";

export const startSession = (auth: ApiAuthorization) => ({
    type: SessionType.START,
    payload: auth,
});

export const clearSession = () => ({
    type: SessionType.CLEAR,
});
