import { Form, Modal } from "antd";
import { FormComponentProps } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import * as React from "react";
import { ComponentType, FormEvent, useCallback } from "react";
import { LikertScaleElement } from "../../../resources/questionnaire/QuestionnaireElement";

interface LikertScaleElementModalStateProps {
    questionnaireId: string;
    visible: boolean;
}

interface LikertScaleElementModalActionProps {
    onSubmit(element: LikertScaleElement): void;
    onCancel(): void;
}

type Props = LikertScaleElementModalStateProps & LikertScaleElementModalActionProps & FormComponentProps;

const LikertScaleElementModal: ComponentType<Props> = (
    {
        form,
        questionnaireId,
        visible,
        onSubmit,
        onCancel,
    }
) => {
    const onOk = useCallback((event: FormEvent) => {
        event.preventDefault();
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            const element: LikertScaleElement = {
                description: values.description,
            };

            onSubmit(element);
        });
    }, [form, onSubmit]);

    return (
        <Modal
            align={null}
            visible={visible}
            destroyOnClose={true}
            title="Dodawanie pytania do skali Likerta"
            okText="Dodaj"
            cancelText="Anuluj"
            onOk={onOk}
            onCancel={onCancel}
        >
            {form.getFieldDecorator("description")(
                <TextArea placeholder="Pytanie"/>
            )}
        </Modal>
    );
};

export default Form.create<Props>()(LikertScaleElementModal);
