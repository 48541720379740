import { Button } from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { ComponentType } from "react";
import DragSortingTable from "../../dragSortingTable/DragSortingTable";
import QuestionnaireElementDeleteButton from "../questionnaireElementDeleteButton/QuestionnaireElementDeleteButton";
import {LikertScaleElement} from "../../../resources/questionnaire/QuestionnaireElement";

export interface LikertScaleElementsStateProps {
  items: LikertScaleElement[];
}

export interface LikertScaleElementsDispatchProps {
  update(elements: LikertScaleElement[]): void;
  sort(dragIndex: number, hoverIndex: number): void;
}

type Props = LikertScaleElementsStateProps & LikertScaleElementsDispatchProps;

const LikertScaleElements: ComponentType<Props> = (props) => {
  const columns: Array<ColumnProps<LikertScaleElement>> = [
    {
      key: "content",
      title: "Skala pytań",
      render: (n, item) => item.description,
    },
    {
      key: "options",
      width: "25%",
      title: "Opcje",
      render: (v, item, idx) => (
        <Button.Group>
          <QuestionnaireElementDeleteButton onDelete={() => {
            let elements = props.items;
            elements.splice(idx, 1);
            props.update(elements);
          }}/>
        </Button.Group>
      ),
    },
  ];

  return (
    <DragSortingTable
      size="small"
      bordered={true}
      rowKey={(item, id) => "sp"+id}
      dataSource={props.items}
      columns={columns}
      onDragSorting={props.sort}
    />
  );
};

export default LikertScaleElements;
