import { connect, MapDispatchToProps, MapStateToProps } from "react-redux";
import { StoreState } from "../../store/storeReducer";
import {userDetailsFormSubmit} from "./store/userDetailsFormActions";
import UserDetailsForm, { UserDetailsFormDispatchProps, UserDetailsFormStateProps } from "./UserDetailsForm";
import {getUser} from "./store/UserDetailsFormSelectors";
import {isAdmin} from "../../store/currentUser/currentUserSelectors";

const mapStateToProps: MapStateToProps<UserDetailsFormStateProps, {}, StoreState> = (state) => {
    const user = getUser(state);

    return {
        name: null == user ? "" : user.name,
        surname: null == user ? "" : user.surname,
        userId: null == user ? "" : user.userId,
        email: null == user ? "" : user.email,
        active: null == user ? false : user.active,
        isAdmin: isAdmin(state),
        loading: state.userDetailsForm.loading,
    }
};

const mapDispatchToProps: MapDispatchToProps<UserDetailsFormDispatchProps, {}> = (dispatch) => ({
    onUpdate: (model) => dispatch(userDetailsFormSubmit(model)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsForm);
