import { Reducer } from "redux";
import { LogInFormTypes } from "./logInFormTypes";

export type LogInFormState = { loading: true } | { loading: false, error?: any };

const initialState: LogInFormState = {loading: false};

export const logInFormReducer: Reducer<LogInFormState> = (state = initialState, action) => {
    switch (action.type as LogInFormTypes) {
        case LogInFormTypes.RESET:
            return initialState;
        case LogInFormTypes.SUBMIT:
            return {loading: true};
        case LogInFormTypes.ERROR:
            return {loading: false, error: action.payload};
    }
    return state;
};
