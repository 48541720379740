import { LOCATION_CHANGE } from "connected-react-router";
import { call, fork, select, take } from "redux-saga/effects";
import createSurveyButtonSaga from "../components/createSurveyButton/store/CreateSurveyButtonSaga";
import logInFormSaga from "../components/logInForm/store/logInFormSaga";
import surveyFormSaga from "../components/surveyForm/store/surveyFormSaga";
import surveyListSaga from "../components/surveyList/store/surveyListSaga";
import userDetailsFormSaga from "../components/userDetailsForm/store/userDetailsFormSaga";
import userListSaga from "../components/userList/store/userListSaga";
import currentUserSaga from "./currentUser/currentUserSaga";
import sessionSaga from "./session/sessionSaga";
import { SessionType } from "./session/sessionTypes";
import errorSaga from "../components/error/store/errorSaga";

export default function* storeSaga() {
    yield call(console.debug, "Run store saga with state %o", yield select());
    yield take(LOCATION_CHANGE);
    yield fork(sessionSaga);
    yield fork(currentUserSaga);
    yield fork(logInFormSaga);
    yield take(SessionType.START);
    yield fork(createSurveyButtonSaga);
    yield fork(surveyListSaga);
    yield fork(userListSaga);
    yield fork(surveyFormSaga);
    yield fork(userDetailsFormSaga);
    yield fork(errorSaga);
}
