import {Empty, Table, Tooltip} from "antd";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import { ComponentType } from "react";
import { SurveyListItem } from "../../resources/survey/List/SurveyListItem";
import { SurveyStatus as SurveyStatusValue } from "../../resources/SurveyEntity";
import EditSurveyButtonContainer from "../editSurveyButton/EditSurveyButtonContainer";
import Percent from "../Percent";
import PublishSurveyButtonContainer from "../publishSurveyButton/PublishSurveyButtonContainer";
import UserName from "../UserName";
import { SurveyListColumnName } from "./SurveyListColumnName";
import PreviewSurveyButtonContainer from "../previewSurveyButton/PreviewSurveyButtonContainer";

export interface SurveyListStateProps {
    items: Item[];
    columns: SurveyListColumnName[];
    total: number;
    page: number;
    pageSize: number;
    loading: boolean;
}

export interface SurveyListDispatchProps {
    onChangePage(page: number, pageSize?: number): void;
}

type Props = SurveyListStateProps & SurveyListDispatchProps;

const SurveyList: ComponentType<Props> = (
    {
        items,
        total,
        page,
        pageSize,
        columns,
        loading,
        onChangePage,
    }
) => (
    <Table
        loading={loading}
        columns={columns.map((key) => columnsDefinitions[key])}
        dataSource={items}
        rowKey="id"
        pagination={{current: page, total, pageSize, onChange: onChangePage}}
        locale={{emptyText: (<Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Brak danych"
          />)}}
    />
);

const columnsDefinitions: Record<SurveyListColumnName, ColumnProps<Item>> = {
    [SurveyListColumnName.NAME]: {dataIndex: "name", title: "Nazwa"},
    [SurveyListColumnName.CODE]: {dataIndex: "code", title: "Kod"},
    [SurveyListColumnName.AUTHOR]: {
        dataIndex: "author",
        title: "Autor",
        render: (user) => user ? <UserName {...user} /> : <i>Nieznany</i>,
    },
    [SurveyListColumnName.CA]: {
        dataIndex: "attemptsCount",
        title: () => <Tooltip title="Ilość wypełnionych / rozpoczętych">W/R</Tooltip>,
        align: "right",
        render: (attemptsCount, {completedCount}) => {
            const title = <Percent value={attemptsCount ? completedCount / attemptsCount : 0} precision={2}/>;

            return <Tooltip
                title={title}
                placement="left"
            >
                {completedCount}/{attemptsCount}
            </Tooltip>;
        },
    },
    [SurveyListColumnName.OPTIONS]: {
        title: "Opcje",
        render: (t, survey) => survey.status === SurveyStatusValue.PUBLISHED ? (
            <PreviewSurveyButtonContainer surveyId={survey.id} />
        ) : (
            <>
                <EditSurveyButtonContainer surveyId={survey.id}/>
                <PublishSurveyButtonContainer surveyId={survey.id}/>
            </>
        ),
    },
};

type Item = SurveyListItem;
export default SurveyList;
