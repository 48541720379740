import generateUuid from "../../utils/generateUuid";
import QuestionnaireType from "./QuestionnaireType";
import {LikertScale} from "./QuestionnaireElement";

export interface Questionnaire {
    questionnaireId: string;
    name: string;
    code: string;
    order: number;
    type: QuestionnaireType;
    scale?: LikertScale;
}

export const createVoidQuestionnaire = (): Questionnaire => ({
    type: QuestionnaireType.DEFAULT,
    code: ``,
    name: ``,
    order: 0,
    questionnaireId: generateUuid(),
});
