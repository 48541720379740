import { SurveyFormType } from "./surveyFormTypes";

export const resetSurveyForm = () => ({
    type: SurveyFormType.RESET,
});

export const loadCurrentSurveyForm = (surveyId: string, step: number) => ({
    type: SurveyFormType.LOAD,
    payload: {surveyId, step},
});

export type LoadCurrentSurveyFormAction = ReturnType<typeof loadCurrentSurveyForm>;
