import {Reducer} from "redux";
import {UserDetailsFormTypes} from "./userDetailsFormTypes";
import {UserEntity} from "../../../resources/UserEntity";
import {userDetailsFormLoaded, userDetailsFormSubmit} from "./userDetailsFormActions";

export interface UserDetailsFormState {
    loading: boolean;
    user: UserEntity|null;
}

const initialState: UserDetailsFormState = {loading: false, user: null};

export const userDetailsFormReducer: Reducer<UserDetailsFormState> = (state = initialState, action) => {
    switch (action.type as UserDetailsFormTypes) {
        case UserDetailsFormTypes.SUBMIT:
            const {payload: {name, surname, userId, email, active}} = action as ReturnType<typeof userDetailsFormSubmit>;
            const user = {name, surname, userId, email, active};
            return {...state, user, loading: true};
        case UserDetailsFormTypes.SAVED:
            return {...state, loading: false};
        case UserDetailsFormTypes.ERROR:
            return {...state, loading: false};
        case UserDetailsFormTypes.UNLOAD:
            return {...state, user: null};
        case UserDetailsFormTypes.LOADED:
            const {payload} = action as ReturnType<typeof userDetailsFormLoaded>;
            return {...state, user: payload};
    }
    return state;
};
